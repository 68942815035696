const es = {
  //Header
  "ProfileName": "Nombre del Perfil",
  "UserRole": "Rol del Usuario",
  "ChangePassword": "Cambia la Contraseña",
  "Profile": "Perfil",
  "Logout": "Cerrar Sesión",
  "UnauthorizedUser": "Usuario no autorizado",

  //Route Constants
  "login": "/ingreso",
  "branchList": "/listaDeSucursales",
  "branchDetails": "/detallesDeLaSucursal",
  "userList": "/listaDeUsuarios",
  "dashboard": "/tablero",
  "minimart": "/colmado",
  "reports": "/informes",
  "dailyStatus": "/estadoDiario",
  "ciInterface": "/interfazCI",
  "ciLoanRequestReport": "/InformeDeSolicitudDePréstamoCi",
  "userprofile": "/perfilDelUsuario",
  "changepassword": "/cambiaLaContraseña",
  "minimartDetails": "/detallesDeColmado",
  "verifyCollection": "/verificarColección",
  "loanPortFolio": "/carteraDePrestamos",
  "dailyStatusSalesPersonDetails":
    "/estadoDiario/detallesDeLaPersonaDeVentas",
  "dailyStatusMessengerDetails":
    "/estadoDiario/detallesDeLeMensajero",
  "dailyStatusMessenger": "/estadoDiario/mensajero",
  "dailyStatusModerator": "/estadoDiario/moderador",
  "loanDetail": "/detallesDelPréstamo",
  "viewLoanApplications": "/verSolicitudDePréstamo",
  "collectionVerfication": "/verificaciónDeColección",
  "configurationDetails": "/detallesDeConfiguración",
  "configurationListEMI": "/listaDeConfiguración/EMI",
  "paymentFrequenciesConfiguration": "/listaDeConfiguración/pagoFrecuenciasConfiguración",
  "gradesConfiguration": "/listaDeConfiguración/estadoPrestamoyGradoConfiguracion",
  "configurationListEarlyPayment":
    "/listaDeConfiguración/pagoAnticipado",
  "configurationListLoanStatus":
    "/listaDeConfiguración/estadoDelPréstamo",
  "configurationListEarlyCancellation":
    "/listaDeConfiguración/cancelaciónAnticipada",
  "configurationListMinimartGrade":
    "/listaDeConfiguración/gradocolmado",
  "configurationListLateFee": "/listaDeConfiguración/cargoporDemora",
  "configurationListLoanPickup":
    "/listaDeConfiguración/recogidaDePréstamo",
  "configurationListGracePeriod":
    "/listaDeConfiguración/periodoDeGracia",
  "configurationListCredoCall":
    "/listaDeConfiguración/llamadaDeCredo",
  "configurationListCommissionScheme":
    "/listaDeConfiguración/esquemaDeLaComisión",
  "configurationListProvision": "/listaDeConfiguración/Provisión",
  "configurationHoliday": "/listaDeConfiguración/Feriado",
  "configurationPaymentDiscount":
    "/listaDeConfiguración/DescuentoPorPago",
  "configurationAnalyst": "/listaDeConfiguración/Analista",
  "configurationNewCreditScore":
    "/listaDeConfiguración/prospectoPuntajeDeCrédito",
  "configurationReward": "/listaDeConfiguración/premio",
  "configurationAdministrativeExpense": "/listaDeConfiguración/gastosAdministrativos",
  "invoiceApproval": "/aprobaciónDeFactura",
  "upcoming": "/próximo",
  "fileContent": "/contenidoDelArchivo",
  "workHistory": "/historialDeTrabajo",
  "userWorkHistory": "/historialDeTrabajoDelUsuario",
  "userWorkHistorySalesPerson":
    "/historialDeTrabajoDelUsuario/personaDeVentas",
  "userWorkHistorySalesPersonDetails":
    "/historialDeTrabajoDelUsuario/detallesDeLaPersonaDeVentas",
  "userWorkHistoryMessenger":
    "/historialDeTrabajoDelUsuario/LeMensajero",
  "userWorkHistoryMessengerDetails":
    "/historialDeTrabajoDelUsuario/detallesDeLeMensajero",
  "userWorkHistoryCollectionsOfficer":
    "/historialDeTrabajoDelUsuario/oficialdeCobros",
  "userWorkHistoryCollectionsOfficerDetails":
    "/historialDeTrabajoDelUsuario/detallesDeOficialDeCobros",
  "workLoad": "/cargaDeTrabajo",
  "Map": "/vistaDeMapaConsolidado",
  "LoanportfolioReports": "/informes/carteraDePrestamos",
  "thirdLevelReports": "/informes/thirdLevelReports",
  "singleCollectionsReports": "/informes/coleccionesUsuario",
  "singleWebCollectionsReports": "/informes/webColeccionesUsuario",
  "singleSalesReports": "/informes/ventasUsuario",
  "singleLoanPortFolioReports": "/informes/carteraDePrestamosUsuario",
  "collectionsReports": "/informes/colecciones",
  "webCollectionsReports": "/informes/webColecciones",
  "salesReports": "/informes/ventas",
  "approvalDashboard": "/panelDeAprobación",
  "TakeOffLateFees": "/quitarLosRecargos",
  "repaymentPlan": "/panelDeAprobación/planDePago",
  "messengerComission": "/esquemadelaComisión/Mensajero",
  "salesPersonComission": "/esquemadelaComisión/Vendedor",
  "salesModeratorComssion": "/esquemadelaComisión/moderadorDeVentas",
  "messengerModeratorComssion":
    "/esquemadelaComisión/moderadorDeMensajería",
  "CommissionSchemeRoute": "/esquemadelaComisión",
  "UserCommissionRoute": "/listaDeConfiguración/esquemaDeComisión",
  "CommissionDetails": "/detallesDeLaComisión",
  "CIWireTransRoute": "/ciwiretransfer",
  "ApprovalMinimartHistory": "/historiaAprobadaDeColmado",
  "ApprovalMinimartDetails": "/detallesDelColmadoDeAprobación",
  "salespersonEfficiencyDashboard": "/panelDeEficienciaDelVendedor",
  "motorcycleDetails": "/detallesDelEnseresYEquipos",
  "loanApproval": "/panelDeAprobaciónDePréstamos",
  "operationLoanApproval":
    "/tableroDeAprobaciónDePréstamosDeOperación",
  "higherCreditLimit": "/panelDeAprobación/eligibilityCriteria",
  "commissionReportURL": "/informeDeLaComisión",
  "walletReport": "/informes/cartera",
  "singleWalletReport": "/informes/informeDeCarteraDeUsuario",
  "remittancePayment": "/pagoDeRemesas",
  "newMinimart": "/newColmado",
  "zonePositionMapping": "/mapeoDePosicionDeZona",
  "specialSalesReports": "/informes/ventasEspeciales",
  "singleSpecialSReports": "/informes/ventasEspecialesUsuario",
  "rewardCatalog": "/CatálogoDeRecompensas",
  "rewardApproval": "/panelDeAprobaciónDeRecompensas",
  "rewardApprovalDetails": "/detallesDeAprobaciónDeRecompensas",
  "mergedMinimartDetails": "/fusionadoMinimartDetalles",
  "notesDetails": "/notasDetalles",
  "applyDiscount": "/manualDescuento",
  // #region MTP
  "/MTPApproval": "/mtpApprobacion",
  "/MTPComplaintPortal": "/mtpPortalDeQuejas",
  "/MTPDashboard": "/mtpTablero",
  "/MTPLadder": "/mtpEscalera",
  "/MTPProducts": "/mtpProductos",
  "/MTPPromotions": "/mtpPromociones",
  "/MTPLoanRequests": "/mtpSolicitudesDePréstamo", //
  "mtpSignupRequest": "/mtpSolicitudDeRegistro",
  "mtpSignupRequestDetails": "/detallesDeLaSolicitudDeRegistro",
  "/MTPEditMinimrtRequests": "/mtpSolicitudDeMinimercado", //
  "mtpPromotionApproval": "/mtpAprobaciónDePromoción",
  "mtpPromotionDetails": "/mtpDetallesDeLaPromoción",

  //User roles
  "ADMIN": "Administración",
  "ANALYST": "Analista",
  "CONFIGURATOR": "Configurador",
  "AUDITOR": "Auditor",
  "SALES_MODERATOR": "Moderador de Ventas",
  "MESSENGER_MODERATOR": "Moderador de Mensajería",
  "OPERATIONS_OFFICER": "Oficial de Operaciones",
  "OPERATIONS_MODERATOR": "Moderadora de Operaciones",
  "GENERAL_MANAGER": "Gerente General",
  "SALES_PERSON": "Vendedor",
  "MESSENGER": "Mensajero",
  "COLLECTIONS_OFFICER": "Oficial de Cobros",
  "ANALYST_MODERATOR": "Moderador Analista",
  "COLLECTIONS_MANAGER": "Gestor de Cobros",
  "COLLECTIONS_SUPERVISOR": "Supervisor de Cobranzas",
  "ADMINISTRATIVE_MANAGER": "Gerente Administrativo",
  "CUSTOMER_SERVICE": "Servicio al Cliente",
  "OPERATIONS_MANAGER": "Gerente de Operaciones",
  "SALES_MANAGER": "Gerente de Ventas",
  "TECHNOLOGY": "Tecnología",

  //Note types
  "ANALYST_INPUT_REFERENCE": "Referencia de entrada del analista",
  "ANALYST_INPUT_RISK": "Riesgo de entrada del analista",
  "ANALYST_INPUT_REGULATOR": "Regulador de entrada del analista",
  "ANALYST_APPROVE": "Aprobación del analista",
  "ANALYST_INCOMPLETE": "Incompleto del analista",
  "ANALYST_REJECTION": "Rechazo del analista",
  "DOWNPAYMENT_COLLECTION": "Colección del pago inicial",
  "DOWNPAYMENT_REVERSAL": "Reversión del pago inicial",
  "OPERATION_OFFICER_REJECTION": "Rechazo del oficial de operaciones",
  "EVALUATION_NOTE": "Nota de evaluación",
  "SELLER_LOAN": "Nota del vendedor",
  "PAYMENT": "Pago",
  "RESCHEDULED_LOAN_DETAILS": "Detalles del préstamo reprogramado",
  "NEGOTIATION_REQUEST": "Solicitud de negociación",
  "NEGOTIATION_RESPONSE": "Respuesta de negociación",
  "INCONSISTENT_PAYER": "Pagador inconsistente",
  "CI_MINIMART_APPROVE": "CI Minimart Aprobar",
  "DISMISSED_LOAN_REQUEST": "Solicitud de préstamo desestimada",
  "SIGN_UP_REQUEST": "Solicitud de registro",
  "CLIENT_STATE_CHANGE": "Cambio de estado del cliente",
  "FOLLOW_UP": "Hacer un seguimiento",
  //Sidebar
  "CIWireTransfer": "Transferencia CI",
  "LoanPortfolio": "Cartera de Préstamos",
  "Operations": "Operaciones",
  "Collections": "Colecciones",
  "Accounting": "Contabilidad",
  "CIInterface": "Interfaz CI",
  "CILoanRequestReport": "Informe de Solicitud de Préstamo Ci",
  "LoanPortFolioManagement": "Gestión de Cartera de Préstamos",
  "EarlyPayment": "Pago Anticipado",
  "EarlyCancellation": "Cancelación Anticipada",
  "MinimartGrade": "Grado Colmado",
  "LateFee": "Porcentaje de Mora",
  "LoanPickup": "Recogida de Préstamos",
  "GracePeriod": "Periodo de Gracia",
  "CredoCall": "Llamada de Credo",
  "CommissionScheme": "Esquema de Comisión",
  "Provision": "Provisión",
  "WorkloadManagement": "Gestión Carga de Trabajo",
  "callNotes": "Notas de la Llamada",
  "Holiday": "Feriado",
  "HOLIDAY": "Feriado",
  "PAYMENT_DISCOUNT": "DESCUENTO POR PAGO",
  "ELIGIBILITY_CRITERIA": "CRITERIO DE ELEGIBILIDAD",
  //Profile
  "UserDetails": "Detalles de Usuario",
  "Edit": "Editar",
  "Deactivate": "Desactivar",
  "fullName": "Nombre Completo",
  "firstName": "Primer Nombre",
  "lastName": "Último Nombre",
  "username": "Usuario",
  "Role": "Tipo",
  "email": "Correo Electrónico",
  "PhoneNumber": "Número de Teléfono",
  "OwnerMobileNumber": "Número de Móvil del Propietario",
  "Save": "Guardar",
  "Back": "Atras",
  "SelectUser": "Usuario",

  "Image": "Imagen",
  "User": "Usuario",
  "of": "de",
  "USER_NOT_FOUND": "No hay ningún usuario disponible para manejar la carga de trabajo del usuario original.",
  "NO_REPORTING_USER_FOUND": "No se encontró ningún usuario de informes.",
  "addZoneManually": "Zona no encontrada para la sucursal seleccionada, agregue la zona manualmente en el campo a continuación.",

  //ProfileMessages
  "UserCreatedSuccessfully":
    "El usuario ha sido creado exitosamente.",
  "UserCreatedSuccessfullyAssignmentsNotDone":
    "El usuario ha sido creado exitosamente. Los cambios se reflejarán en unos minutos.",
  "UserUpdatedSuccessfully":
    "El usuario ha sido actualizado exitosamente.",

  // Login
  "Sanerret": "Sanerret",
  "LoginCopyrightText": "Derechos de autor © $ Matrices",
  "Password": "Contraseña",
  "ForgotPassword": "Olvido su Contrasena?",
  "Login": "Iniciar Sesión",

  // Errors
  "Error": "Error",
  "SorryAnErrorHasOccurred":
    "Lo sentimos, se ha producido un error. Inténtalo de nuevo.",
  "InvalidInput": "Por favor ingrese una entrada válida.",
  "NetworkNotReachable": "Error al conectar con el servidor.",
  "ConnectionError": "Error de Conexión",
  "EmptyFieldsError":
    "Por favor, rellene todos los campos obligatorios.",
  "InvalidLoginCredentials": "Credenciales de acceso inválidos",
  "EmptyUsernameError": "Por favor ingrese su nombre de usuario.",
  "EmailHasBeenSent":
    "El correo electrónico ha sido enviado al usuario.",
  "EmailNotSent":
    "No se puede enviar un correo electrónico al usuario.",
  "InvalidOldPassword": "Contraseña antigua inválida.",
  "InvalidMatricesCode": "Por favor ingrese un código de matrices válido.",
  "UserNotActive": "El usuario no está activo.",
  "UsernameAlreadyExists": "El nombre de usuario ya existe.",
  "RoleNotFound": "Rol no encontrado.",
  "FailedToSendEmail":
    "No se puede enviar un correo electrónico al usuario.",
  "AccessNotFound": "Acceso no encontrado !!",
  "InvalidRequestParameters": "Parámetros de solicitud inválidos.",
  "SearchParametersCanNotBeAllNull":
    "Los parámetros de búsqueda no pueden ser todos nulos.",
  "ErrorNewPasswordAndConfirmPasswordAreNotSame":
    "La nueva contraseña y la contraseña de confirmación no coinciden.",
  "ErrorInvalidOldPassword": "Contraseña antigua inválida",
  "ErrorInvalidEmail": "Correo electrónico inválido.",
  "ErrorInvalidBranchName": "Nombre de sucursal no válido",
  "DuplicateUserName": "Nombre de Usuario ya existe",
  "InvalidBranchCount":
    "No se puede asignar más de una rama al usuario.",
  "USER_DEACTIVATION_FAILED":
    "No se puede desactivar al usuario ya que algunos préstamos todavía están asociados con el usuario.",
  "INVALID_USER_DEACTIVATION_REQUEST":
    "Solicitud de desactivación de usuario no válida",
  "ACCOUNT_LOCKED_ERROR_MESSAGE":
    "La cuenta está bloqueada. Vuelve a intentarlo más tarde.",
  "PositionsNotAvailableError": "Posiciones no disponibles.", //
  "CollectionOfficersNotAvailableError":
    "Oficiales de cobranza no disponibles.", //
  "PaymentCannotBeMade": "No se puede realizar el pago desde esta pestaña debido a un formulario de préstamo incompleto. Utilice la pestaña de pago del préstamo.",
  "oldActiveLoansPresentNote": "Este minimercado tiene los siguientes préstamos activos $ cuyo pago no será aceptado por el sistema. Por favor, reestructure dichos préstamos para que se acepten sus pagos.",
  "loanSpecificPayment": "Para los ID de préstamo * puede pagar monto de hasta #",
  "CannotMakePaymentForLoan": "No se puede realizar el pago del ID de préstamo $, ya que los datos del formulario de solicitud están pendientes.",
  "NOTE_INVALID_CONTEXT":"Nota El contexto no es válido.",

  //Change Password
  "OldPassword": "Contraseña Anterior",
  "NewPassword": "Nueva Contraseña",
  "ConfirmPassword": "Confirmar Contraseña",
  "PasswordChanged": "Contraseña Cambiada",
  "YourPasswordHasBeen": "Tu contraseña ha sido",
  "ChangedSuccessfully": "cambiado con éxito!",
  "LoginAgain": "Ingrese de Nuevo",
  "Submit": "Enviar",
  "Cancel": "Cancelar",

  // User Management
  "UserManagement": "Manejo de Usuario",
  "AddUser": "Agregar Usuario",
  "NoRecordsFound": "No se encontrarón archivos",
  "BranchOfficer": "Oficial de Sucursal",
  "CollectionsOfficer": "Oficial de Cobros",
  "LOAN_PORTFOLIO_MANAGER": "Gestor de Cartera de Préstamos",
  "ACCOUNTING_OFFICER": "Oficial de Cuentas",
  "Search": "Buscar",
  "Refresh": "Refrescar",
  "Filter": "Buscar",
  "DeactivateUser": "Desactivar Usuario",
  "ConfirmDeactivateUser":
    "¿Seguro que quieres desactivar al usuario?",
  "Yes": "Si",
  "No": "No",
  "NoAssignee": "No Asignado",
  "Moderator": "Moderadora",
  "MinimartMAnagement": "Gestión de Colmado",
  "miniMArtManagement": "Gestión de Colmado",
  "Rating": "Clasificación de Rendimiento",
  "selectZone": "Zona del vendedor",
  "DailyStatus": "Estatus Diario",
  "NoRating": "Sin Valuación",
  "NoBranch": "Sin Sucursal",

  // MESSENGER:"",
  "ReportTo": "Reportar a",
  "SelectReportingUser": "Usuario de Informes",
  "SelectCollectionOfficer": "Seleccionar Oficial de Cobranza", //
  "SelectPosition": "Seleccionar Posicion", //
  "position": "Posicion", //

  // Version Table
  "Version": "Versión",
  "State": "Estados",
  "LastModified": "Última Modificación",
  "Active": "Activo",
  "Inactive": "Inactivo",
  "Drafted": "Borrador",
  "SelectVersion": "Estatus",

  //Status
  "INACTIVE": "INACTIVO",
  "DRAFTED": "BORRADOR",

  // Loan Provision
  "LoanProvisions": "Provisiones de Préstamos",
  "ListOfProvisions": "Lista de Disposiciones",

  // Loan Status
  "LoanStatus": "Estatus del Préstamo",
  "LoanStatusConfig": "Configuración del Estatus del Préstamo",
  "Status": "Estatus",
  "ArrearsFrom": "Atrasos en Días (Desde)",
  "ArrearsTo": "Atrasos en Días (Hasta)",
  "Notes": "Notas",
  "W_OFF": "Compulsivo",
  "Legal": "Legal",
  "Closed": "Cerrado",
  "DISAPPEARED": "Incobrable",
  "PAYMENT_AGREEMENT": "Acuerdo de Pago",

  //Loan Late Fees
  "LateFeeConfig": "Configuraciones de Pago Tardío",
  "LateFees": "Cargo por Demora ($)",
  "LateFeesPercent": "Cargos por Porcentaje de Mora %",

  //Loan Details
  "loanDetails": "Detalles del Préstamo",
  "CategoryDisabled": "Seleccione el Estatus como 'Desembolsado'",
  "amountRequested": "Monto Solicitado ($)",
  "details": "Detalles",
  "disbursedDate": "Fecha de Desembolso",
  "status": "Estatus",
  "termRequested": "Plazo Solicitado",
  "daysInArrears": "Días en Atrasos",
  "days": "Dias",
  "interestRate": "Tasa de Interés (%)",
  "approvedInterestRate":"Tasa de interés aprobada (%)",
  "lateFees": "Porcentaje de Mora (%)",
  "appliedDaysInDelays": "Días de Retraso Aplicados",
  "appliedMaxPercentage": "Capital Pendiente Para Mora (%)",
  "balanceToDate": "Saldo a la Fecha ($)",
  "pastDueBalance": "Monto en Atraso ($)",
  "index": "Índice",
  "dueDate": "Fecha de Vencimiento",
  "NA": "N / A",
  "AmortizationTable": "Tabla de Amortización",
  "amountToPay": "Monto a Pagar",
  "DUE": "Debido",
  "dueCollection": "Debida Cobranza",
  "pastDueCollection": "Cobro Vencido",
  "PAID": "Pagado",
  "PARTIALLY_PAID": "Parcialmente Pagado",
  "PASS_DUE": "Pasar Debido",
  "PARTIALY_PAID": "Pago Parcial",
  "PAST_DUE": "Pasado Debido",
  "provisionClass": "Clase de Provisión",
  "provisionAmount": "Monto de la Provisión ($)",
  "emi": "EMI ($)",
  "lateFeesAmt": "Cantidad de Tarifas Tardías ($)",
  "percent": "Porcentaje (%)",
  "amt": "Cantidad ($)",
  "Info": "Información",
  "PleaseEnterAmount": "Por favor ingrese el monto",
  "LOAN_NOT_FOUND": "Préstamo no encontrado",
  "InvalidNegotiationRequest": "Solicitud de negociación inválida",
  "NegotiationNotFound": "Negociación no encontrada",
  "InvalidNegotiationAmount": "Monto de negociación inválido",
  "InvalidNegotiationResponse": "Respuesta de negociación inválida",
  "InvalidNegotiationState": "Estatus de negociación inválido",
  "requester": "Solicitante",
  "Request": "Solicitud",
  "Approve": "Aprobar",
  "Reject": "Rechazar",
  "REJECT": "Rechazar",
  "ViewTakeOffRequest": "Ver Solicitud Eliminar Mora",
  "approver": "Aprobador",
  "approvalDate": "Fecha de Aprobación",
  "RequestDate": "Fecha de Solicitud",
  "viewAssignRepaymentPlan": "Ver Plan de Pago Asignado",
  "NoRequest": "Ninguna Solicitud",
  "RequestCreatedSuccessfully": "Solicitud Creada Exitosamente",
  "RequestApprovedSuccessfully": "Solicitud Aprobado Exitosamente",
  "RequestRejectedSuccessfully": "Solicitud Rechazada Exitosamente",
  "createdLoanCount": "Creada",
  "disbursedLoanCount": "Desembolsados",
  "RescheduledLoanId": "ID de Préstamos Reprogramados : ",
  "RescheduledLoanDate": " Fecha de Préstamo Reprogramada",
  "RescheduledLoanAmont": "Monto de Pago :",
  "CloseLoanUserName": "Este préstamo está cerrado por ",
  "AppDataIsNotFilled": "Nota : Este minimercado ha desembolsado un préstamo cuyos datos no están completos.",
  "AppDataIsNotFilledForLoan": "Nota : Los datos de la solicitud no están completos para este préstamo.",
  "CloseLoanDate": " en ",
  "CloseLoanNote": "Nota : ",
  "Note": "Nota",
  "NoteForBlackListingCedula": "La nota es obligatoria.",
  "CloseDetailsNotAvailable":
    " Detalles del préstamo cerrado no disponibles",
  "LoanRescheduledBy": "Reprogramado por",
  "loanDetailsPdfDownloadNotAllowed": "La descarga del estado de cuenta puede ser posible después del desembolso del préstamo.",
  "EMI_payment": "Emi y Gastos Administrativos",
  "DownPayment": "Pago Inicial",

  // Branch List
  "Name": "Nombre",
  "Address": "Dirección",
  "SelectBranchStatus": "Estatus",
  "BranchManagement": "Manejo de Sucursales",
  "AddBranch": "Agregar Sucursal",
  "Zone": "Zona del vendedor",

  "pleaseHoverOnLegendForDetails":
    "Pasa el cursor sobre las leyendas para más detalles.",

  // Dashboard
  "Dashboard": "Panel Informativo",
  "SearchClientOrLoan": "Buscar Cliente o Préstamo",
  "Reports": "Informes",
  "Provisions": "Provisiones",
  "LoanPortfolioManagement": "Gestión de Cartera de Préstamos",
  "TotalLoanPortfolio": "Cartera Total de Préstamos",
  "TotalLoans": "Total de Préstamos",
  "PastDue": "En Atrasos",
  "PastDues": "En Atrasos",
  "Dues": "Deudas",
  "DefaultRate": "Índice de Morosidad",
  "AvgLoanAmount": "Monto Promedio de Préstamo",
  "AvgTerm": "Plazo Promedio",
  "KnowMore": "Saber Más",
  "Months": "Meses",
  "Years": "Años",
  "DailyUpdate": "Actualización Diaria",
  "PortfolioRepayments": "Portafolio Reembolsos",
  "Date": "Fecha",
  "Repayment": "Reembolso",
  "RepaymentNo": "Número de Reembolso",
  "ComingSoon": "Próximamente",
  "Amount": "Monto",
  "From": "Desde",
  "To": "A",
  "FilterByAmount": "Filtrar por Monto",
  "FilterByInterestRate": "Filtrar por Tasa de Interés",
  "FilterByTerm": " Filtrar por Plazo",
  "LoanDisbursed": "Préstamo Desembolsado",
  "NewLoans": "Nuevos Préstamos",
  "DateOfDisbursement": "Fecha de Desembolso",
  "View": "Ver",
  "noData": "",
  "rate": "Calificar (%)",
  "duration": "Duración (cuotas)",
  "Finance": "Finanzas",
  "Amount(Lower)": "Menor Monto ($) ",
  "Amount(Upper)": "Mayor Monto ($) ",
  "totalInterestPaid": "Intereses Recogido",
  "totalLateFeesPaid": "Tarifas Tardías Recogido",

  // Months
  "January": "Ene",
  "February": "Feb",
  "March": "Mar",
  "April": "Abr",
  "May": "May",
  "June": "Jun",
  "July": "Jul",
  "August": "Ago",
  "September": "Sep",
  "October": "Oct",
  "November": "Nov",
  "December": "Dic",

  //Branch Management
  "DeactivateBranch": "Desactivar Sucursal",
  "DoYouWantToDeactivateBranch": "¿Quieres desactivar al Sucursal?",
  "BranchDetails": "Detalles de la Sucursal",
  "BranchName": "Nombre de Sucursal",
  "BranchDeactivatedSuccessfully":
    "Sucursal desactivada exitosamente",
  "BranchCreatedSuccessfully": "Sucursal creada exitosamente",
  "BranchUpdatedSuccessfully": "Sucursal actualizada exitosamente",
  "Zones": "Zonas",

  //Search Client/Loan
  "NameSurname": "Nombre/Apellido",
  "LoanId": "ID del Préstamo",
  "DateOfBirth": "Fecha de Nacimiento",
  "DNINIE": "DNI/NIE",
  "FilterByBranch": "Filtrar por Sucursal",
  "WrittenOff": "castigado",
  "AmountDisbursement": "Importe Desembolso",
  "Interest": "Interesar ($)",
  "Term": "Término",
  "Branch": "Sucursal",
  "DisbursementDate": "Fecha de Desembolso",
  "CustomerId": "ID cliente",

  "ClientLoanStatus": "Estatus del Préstamo del Cliente",
  "ToPayOffLoan": "Para Pagar el Préstamo",
  "UpToDate": "A hoy",
  "Capital": "Capital",
  "Arrears": "Atraso",
  "OtherCharges": "Otros Cargos",
  "LoanStatusReport": "Reporte de estatus del préstamo",
  "RepaymentSchedule": "Esquema plan de pago",
  "selectBranch": "Sucursal",

  //Collections
  "LoanPortfolioStatusReport":
    "Reporte de estatus cartera de préstamo",
  "LoanInPastDue": "Préstamos en Atrasos",
  "DaysInPastDue": "Días en Atrasos",
  "ProvisionClassification": "clasificación de Provisión",
  "ByCollectionsOfficer": "Por Oficial de Cobranza",
  "Apply": "Aplicar",
  "FromDate": "De",
  "ToDate": "A",
  "Classification": "Clasificación",
  "DateRange": "Rango de Fechas",

  // Configuration List
  "ConfigurationManagement": "Gestión de la Configuración",
  "ConfigurationManagement_EARLY_PAYMENT": "PAGO ANTICIPADO",
  "ConfigurationManagement_LOAN_STATUS": "ESTADO DEL PRÉSTAMO",
  "ConfigurationManagement_EMI": "PAGO DE CUOTAS",
  "ConfigurationManagement_EARLY_CANCELLATION":
    "CANCELACIÓN ANTICIPADA",
  "ConfigurationManagement_GRADING_SCALE": "GRADO COLMADO",
  "ConfigurationManagement_LOAN_PICKUP": "RECOGIDA DE PRÉSTAMO",
  "ConfigurationManagement_GRACE_PERIOD": "PERIODO DE GRACIA",
  "ConfigurationManagement_PROVISION": "PROVISIÓN",
  "ConfigurationManagement_HOLIDAY": "DÍA FESTIVO",
  "ConfigurationManagement_PAYMENT_DISCOUNT": "DESCUENTO POR PAGO",
  "ConfigurationManagement_ELIGIBILITY_CRITERIA":
    "CRITERIO DE ELEGIBILIDAD",

  "ConfigurationManagement_AUTO_ANALYSIS": "ANÁLISIS AUTOMÁTICO",
  "AUTO_ANALYSIS": "ANÁLISIS AUTOMÁTICO",
  "SelectStatus": "Estatus",
  "GRADING_SCALE": "GRADO COLMADO",
  "ConfigurationManagement_REWARDS": "Premio",

  "ConfigurationManagement_CREDO_CONFIG": "LLAMADA DE CREDO",

  "ConfigurationManagement_PAYMENT_FREQUENCY": "FRECUENCIA DE PAGO",
  "ConfigurationManagement_LOAN_STATUS_AND_GRADE":"ESTADO Y GRADO DEL PRÉSTAMO",
  //Configurations Common
  "Settings": "Configuraciones",
  "EARLY_PAYMENT": "PAGO ANTICIPADO",
  "LOAN_STATUS": "Estatus del Préstamo",
  "EARLY_CANCELLATION": "CANCELACIÓN TEMPRANA",
  "LOAN_PICKUP": "RECOGIDA DE PRÉSTAMO",
  "LATE_FEES": "CARGOS POR PORCENTAJE DE MORA",
  "GRACE_PERIOD": "PERIODO DE GRACIA",
  "CREDO_CONFIG": "LLAMADA DE CREDO",
  "PROVISION": "Provisión",
  "COMMISSION_SCHEME": "Esquema de Comisiones",
  "EMI": "Pago de Cuota",
  "CreatedBy": "Creado por",
  "Set": "Aceptar",
  "Activate": "Activar",
  "SaveDraft": "GUARDAR BORRADOR",
  "Reset": "Reiniciar",
  "DeleteDraft": "Eliminar Borrador",
  "Type": "Tipo",
  "Percentage": "Porcentaje",
  "DaysArrears_lower": "Atrasos Dias >=",
  "DaysArrears_upper": "Atrasos Dias <=",
  "PercentWithCollateral": "% Con Garantía",
  "PercentWithoutCollateral": "% Sin Garantía",
  "provisionType": "Tipo de provisión",
  "provision/terms": "Provisión/Términos",
  "EarlyCancellationCharges": "Cargos por Cancelación Anticipada",
  "PickupFrequency": "Frecuencia de Recogida",
  "BackToTheVersionList": "¿Seguro que quieres volver?",
  "discountPerEarlyEMIPercent":
    "Descuento por porcentaje EMI temprano ( % )",
  "graceDays": "Días de Gracia (dias)",
  "lateFeesPercent": "Porcentaje de Mora (%)",
  "maxCapitalPercentage":"Porcentaje Máximo de Capital pendiente (%)",
  "startCalculatationFromDay":"Días de atraso",
  "fieldsCannotBeEmpty":"Los Campos no Pueden Estar Vacíos",
  "SuccessActivate": "Configuración Activada",
  "SaveAsDraft": "Guardar Como Borrador",
  "SaveAsDraftConfirmationMessage":
    "¿Está seguro de que desea guardar esta configuración como borrador?",
  "ResetConfirmationMessage":
    "¿Está seguro de que desea restablecer los cambios de configuración?",
  "ActivateConfirmationMessage":
    "¿Seguro que quieres activar esta configuración?",
  "DeleteDraftConfirmationMessage":
    "¿Está seguro de que desea eliminar este borrador de configuración?",
  "SuccessReset":
    "Los datos de configuración se restablecen con éxito",
  "SuccessGetDrafted": "Borrador guardado exitosamente",
  "SuccessGetActive": "Se obtuvo la versión actual con éxito",
  "SuccessSaveAsDraft": "Configuración guardada como borrador",
  "SuccessDeleteDraft": "Borrador eliminado",

  "ThisWillTakeSomeTime": "Esto tomara un tiempo...",

  "InvalidIntegerValue": "Valor no Válido",
  "ErrorConfigurationNotFound": "Configuración no Encontrada",
  "ErrorCannotUpdateConfig":
    "No permitido actualizar ya que no eres el propietario.",
  "ErrorProvisionNotDrafted":
    "El estado de configuración de provisión no está redactado",
  "ErrorConfiguration": "Hubo un Error",
  "ErrorInvalidConfiguration": "Cadena de configuración no válida",
  "ErrorVersionExists": "Esta versión ya existe",
  "ErrorDraftedConfigurationExists":
    "No se puede crear una nueva configuración, ya que ya hay una redactada.",
  "ErrorConfigurationNotDrafted":
    "El estado de configuración no está escrito",
  "ErrorDraftedNotFound":
    "No se han encontrado configuración de borrador",
  "InvalidConfigurationData": "Datos de configuración no válidos.",
  "ErrorDraftedConfigNotFound":
    "No se ha encontrado ninguna configuración de emi redactada",
  "ErrorActiveNotFound":
    "No se encontró ninguna configuración activa",
  "ErrorEmptyValue": "El valor de entrada no debe estar vacío.",
  "DaysArrearsRangeInvalid":
    "El rango de días de Arrear no debe superponerse.",
  "ErrorInvalidArrearDaysRange": "Rango inválido de días de atrasos",
  "ErrorDaysRangeShouldStartWithZero":
    "Rango de días de atrasos debe comenzar con cero",
  "InvalidPercentage": "Valor Porcentual No Válido.",
  "invalidDays":"Días Ingresados no Válidos.",
  "Value":"Valor",
  "ErrorDaysLowerMustBeLessThanDaysUpper":
    "Los días inferiores deben ser menos que los días superiores",
  "ErrorDuplicateTypeValues":
    "Valores duplicados no permitidos para 'Tipo'",
  "ConfigurationFetchSuccessfully":
    "La configuración fue recuperada exitosamente.",
  "ErrorBranchNotFound": "Sucursal no encontrada",
  "ErrorBranchNotDefined": "sucursal no definida",
  "ErrorBranchAlreadyExists":
    "La sucursal con el mismo nombre ya existe",

  "EMIAmountRangeInvalid": "Rango de cantidad de cuota invalido.",
  "ErrorEMIAmountLowerMustBeLessThanAmountUpper":
    "Error La cantidad de la cuota inferior debe ser menor que la cantidad mayor.",
  "LeveleFreuenciesCantBeEdited": "Advertencia : Esta es una configuración archivada y no se puede editar aquí. Para editar las frecuencias de los niveles, consulte la sección de frecuencia de pago.",
  "disabledConfiguration": "Información: Esta configuración no se puede editar aquí. Consulte el estado del préstamo y la configuración de calificaciones",
  "disableProvisionConfig": "Información: Esta configuración está deshabilitada porque tiene estado de préstamo D1, D2 y E, a partir de ahora hemos eliminado este estado.",
  "disabledConfig":"Información: Esta configuración está deshabilitada y no se puede editar.",
  //Loan status and Grade configuartion
  "repeatedDaysPastDue": "Los días vencidos no deben repetirse",
  "repeatedTerms": "Los términos no deben repetirse",
  "daysInAscendingOrder": "Los días vencidos no están en orden ascendente",
  "zeroTermError": "El término no puede ser 0",
  //Pagination
  "PerPage": " Por Página",

  //CI_INTERFACE
  "selectMiniMartGrade": "Calificación del Colmado",
  "selectTypeOfCustomer": "Tipo de Cliente",
  "selectDate": "Seleccione Fecha",
  "category": "Grado",
  "zone": "Zona del vendedor",
  "mzone": "Zona de Mensajería",
  "type": "Tipo",
  "name": "Nombre",
  "UploadCSV": "Subir Archivo",
  "fileTypeNote": "El archivo debe tener el tipo CSV",
  "Go": "Ir",
  "Upload": "Elija el Archivo",
  "TheUploadActionCannotBeUndone":
    "La acción de carga no se puede deshacer",
  "LastUpdated": "Última Actualización",
  "New": "Nuevo",
  "Historical": "Histórico",
  "UP_FOR_RENEWAL": "Para Renovar",
  "NEW": "Nuevo",
  "Prospecto": "Prospecto",
  "MiniMartname": "Nombre del Colmado",
  "minimartName": "Nombre del Colmado",
  "NotAvailable": "N / A",
  "ScheduledSuccessFully": "Programado Exitosamente",
  "ScheduleNow": "Programar Ahora",
  "FileYetToUpload": "Archivo Aún Por Subir",
  "minimarts": "Colmados",
  "noDataUploaded": "Sin Datos Cargados",
  "FileTypeMustCSV": "Tipo de Archivo Debe CSV",
  "CSVFileUploadedSuccessfully": "Archivo CSV Cargado Correctamente",
  "documentNotFound": "Documento No Encontrado",
  "schedularProcessError":
    "Se produjo un error al ejecutar el proceso programado.",
  "ScheduledProcessFailed": "Proceso Programado Fallido",
  "InconsistentDataInFile": "Datos Inconsistentes en el Archivo",
  "NoDocsForProcessing":
    "No hay documentos para procesar, suba un nuevo archivo",
  "ViewPrevious": "Lista de Archivos",
  "DoYouReallyWantToDelete": "¿De verdad quieres eliminar",
  "these": "Estos",
  "this": "",
  "files": "Archivos",
  "ErrorNoSelection":
    "No hay archivos seleccionados, seleccione para eliminar",
  "Delete": "Eliminar",
  "PreviousFiles": "Archivos Cargados Previamente",
  "NoFilesOnServer": "No hay archivos en el servidor",
  "fileName": "Nombre del Archivo",
  "select": "Seleccione",
  "ok": "Ok",
  "DeleteFileSuccess": "Eliminar archivo con éxito",
  "PROCESSED": "Procesada",
  "PROCESSING": "Procesando",
  "FAILED": "Ha Fallado",
  "ErrorInFile": "Error en Archivo",
  "Uploaded": "Cargado",
  "Processed": "Procesado",
  "InvoiceList": "Lista de Facturas",
  "NO_ACTION": "No Acción",

  //MiniMartManagement:
  "minimartManagement": "Gestión de Colmados",
  "miniMarketID": "ID de Colmado",
  "MiniMarketName": "Nombre Colmado",
  "creditLimit": "Límite de Crédito",
  "PaymentReceipts": "Recibos de Pago",
  "EligibleFor": "Elegible Para",
  "MinimartStatus": "Estatus Colmado",
  "MinimartType": "Tipo Colmado",
  "City": "Ciudad",
  "Association": "Asociación",
  "associatedBranch": "Sucursal",
  "associatedZone": "Zona del vendedor",
  "associatedMessengerZone": "Mensajero Zona",
  "AssociatedSalesPerson": "Vendedor",
  "AssociatedMessenger": "Mensajero",
  "AssociatedModerator": "Moderador",
  "PortFolio": "Portafolio",
  "Invoices": "Facturas",
  "Loans": "Préstamos",
  "selectInvoiceStatus": "Estado de Factura",
  "Rejected": "Rechazado",
  "REJECTED": "Rechazado",
  "number": "Numero",
  "date": "Creación",
  "expiryDate": "Expiración",
  "amount": "Cantidad",
  "balance": "Balance",
  "REJECTED_BY_SYSTEM": "Rechazado",
  "APPROVED": "Aprobado",
  "DISBURSED": "Desembolsada",
  "INCOMPLETE": "Incompleto",
  "loanAmount": "Monto del Préstamo",
  "applicationDate": "Aplicado en",
  "disbursementDate": "Fecha de Desembolso",
  "loanType": "Tipo de Préstamo",
  "NotDisbursed": "No Desembolsado",
  "Disbursed": "Desembolsado",
  "Approved": "Aprobado",
  "Incomplete": "Incompleto",
  "FACTORING": "FACTURAS CI",
  "Dismissed": "Desestimar",
  "Analysis": "Análisis",
  "InProgress": "En Progreso",
  "Expired": "Expirado",
  "Created": "Creado",
  "CLOSED": "Cerrado",
  "drawnAmount": "Monto Retirado",
  "drawnLimit": "Límite de Retiro",
  "ACTIVE": "ACTIVO",
  "BARRED": "PROHIBIDO",

  //superDashboard
  "minimartCategories": "Grados",
  "minimartTypes": "Tipos",
  "MiniMarts": "Colmados",
  "SalesPersons": "Vendedors",
  "Messengers": "Mensajeros",
  "HISTORICAL": "Histórico",
  "cashTopUp": "Recarga de Efectivo",
  "Consumer": "Convenio",

  // UP FOR RENEWAL:"",
  "months": "Meses",
  "Count": "Contar",
  "NoData": "Sin Datos",
  "A": "A",
  "B": "B",
  "C": "C",
  "D": "D",
  "E": "E",
  "UpForRenewal": "Para Renovación",
  "list": "Lista",
  "LoanTaken": "Préstamo Tomado",

  "TheseFilesAreFailed": "Estos Archivos Fallan",
  "CSVFileUploadError": "No se pudieron procesar archivos",
  "applications": "Aplicaciones",
  "CREATED": "Creada",
  "DISMISSED": "Desestimar",
  "ANALYSIS": "Analisis",
  "SUBMITTED": "Sometido",
  "LEGAL": "Legal",

  // Daily Status
  "generatedLoansCount": "No. Préstamos",
  "loan": "Préstamo",
  "taken": "Tomado",
  "notTaken": "no Tomado",
  "Grade": "Grado",
  "branch": "Sucursal",
  "selectRatings": "Calificaciones",
  "selectVisitStatus": "Estatus de Visita",
  "visited": "Visitó",
  "tobeVisited": "Ser Visitado",
  "TotalCount": "Cuenta total",
  "assignedMinimarts": "Colmados Asignados",
  "salesPersonList": "Lista de Vendedores",
  "created": "Creada :  ",
  "disbursed": "Desembolsada :  ",
  "pending": "Pendiente :  ",
  "Effectiveness": "Eficiencia (%)",

  // Verify Collections
  "CollectionVerification": "Verificación de Cobros",
  "DateAndTime": "Fecha y Hora",
  "WorkHistory": "Historial de Trabajo",
  "ManageWorkload": "Gestionar Carga de Trabajo",
  "Receipt": "Recibo",
  "ReEnterAmount": "Re Ingrese la Monto",
  "Verify": "Verificar",
  "VerifyCollection": "Verificar Cobro",
  "EnterAmount": "Ingresar Monto",
  "TotalAmountCollected": "Monto total Recaudado :",
  "Deposited": "Depositado",
  "cashDeposited": "Efectivo Depositado :",
  "cashInTransit": "Efectivo en Tránsito :",
  "amountCollectedByTransfer": "Cobrado por transferencia :",
  "AdministrativeExpense": "Gastos Administrativos : ",
  "CollectionDetails": "Detalles de la Colección",
  "EMIAndInitialCash": "EMI y efectivo Inicial : ",
  "CashDetails": "Detalles de Efectivo",
  "amountCollectedBycash": "Cobrado en Efectivo :",
  "amountCollectedByDeposit": "Cobrado por Depósito :",
  "Intransit": "En Tránsito",
  "ViewReceipt": "Ver Recibo",
  "totalMinimart": "Total de Colmados",
  "amountCollected": "Monto Recaudado",
  "amountToBeCollected": "Para Recoger",
  "arrearsAmount": "Atrasada",
  "InTransit": " Monto en Tránsito",
  "extraAmount": "Extra",
  "pendingAmount": "Pendiente",
  "totalAmount": "Total",
  "totalAssignedMinimarts": "Total Colmados Asignados",
  "visitedMinimarts": "Colmados Visitados",
  "MessengersList": "Lista de Mensajeros",
  "amountDeposited": "Depositada ($)",
  "amountVerified": "Verificada ($)",
  "Action": "Acción",
  "actionStatus": "Estatus de Acción",
  "discrepency": "Discrepancia",
  "verified": "Verificado",

  //Workload Management
  "WorkLoadManagement": "Gestión Carga de Trabajo",
  "AssignedMiniMarkets": "Colmados Asignados",
  "EnterCIID": "Ingrese el ID de CI",
  "EnterMatricesId": "Escriba su Codigo de Matrices",
  "WorkloadOfAssignMinimarts":
    "Carga de Trabajo de {{salesPerson}} Asignar Colmados",
  "VisitStatus": "Estatus de Visita",
  "ChangeAssignmentsFor": "Cambiar Asignación Para",
  "Daily": "Diario",
  "Permanent": "Permanente",
  "All": "Todos",
  "FromPerson": "De Persona",
  "CIID": "CI ID",
  "ciId": "CI ID",
  "ToPerson": "A Persona",
  "AssignMinimarts": "Colmados Asignados",
  "Assign": "Asignar",
  "selectRole": "Rol",
  "PleaseSelectRequiredFields":
    " Seleccionar Rol, Sucursal y Asignación",
  "PleaseSelectAllVaildOptions":
    "Seleccione Todas las Opciones Válidas",
  "TodaysAssignment": "Asignación de Hoy",
  "MinimartsAssignedSuccessfully": "Colmados Asignado Exitosamente",
  "MinimartsNotAssignedError": "Colmados no Asignados ",
  "AssignmentType": "Tipo de Asignación",

  "MapWithMinimartGrade": "Mapa Con Calificación de Colmado",

  // Loan PortFolio Management
  "selectProvisionGrade": "Grado de Provisión",
  "selectLoanStatus": "Estatus de Préstamo",
  "selectRemittanceStatus": "Estado de Remesa",
  "SelectLoanType": "Tipo de Préstamo",
  "loanId": "ID del Préstamo",
  "createdLoanID": "Creado ID del Préstamo",
  "closedLoanID": "Cerrado ID de Préstamo ",
  "ClosedApplicationID": "ID de aplicación cerrada",
  "CreatedApplicationID": "ID de aplicación creada",
  "closed": "Cerrado ",
  "loanPortFolioManagementDetails": "Detalle de Manejo de Cartera ",
  "loanApplication": "Solicitud de préstamo",
  "assignNewRepaymentPlan": "Asignar Nuevo Plan de Pago",
  "viewLoanApplication": "Ver Solicitud de Préstamo",
  "takeOffLateFees": "Quitar los Recargos",
  "SelectCategory": "Categoría",
  "ApplicationStatuses": "Estados de Solicitud",
  "LoanCategories": "Categorías de Préstamos",
  "Assigned": "Asignado",
  "Actions": "Acciónes",
  "minimartId": "ID de colmado",
  "minimartCode": "CI ID",
  "address": "Dirección",
  "code": "CI ID",
  "ownerName": "Nombre del Dueño",
  "minimartCIId": "CI ID",

  // View Loan Application
  "ViewLoanApplication": "Ver Solicitud de Préstamo",
  "LoanApplicationDetails": "Detalles de la Solicitud de Préstamo",
  "InvoiceNumber": "Número de Factura",
  "TotalInstallments": "Cuotas Totales",
  "TypeOfLoan": "Tipo de Préstamo",
  "AmountPaid": "Montos Pagada",
  "TotalAmount": "Monto Total",
  "BalanceAmount": "Balance Total",
  "References": "Referencias",
  "comercialRef1": "Referencia Comerciales 1",
  "comercialRef2": "Referencia Comerciales 2",
  "personalRef1": "Referencia Personales 1",
  "personalRef2": "Referencia Personales 2",
  "neighbourhoodRef1": "Referencia Barrio 1",
  "neighbourhoodRef2": "Referencia Barrio 2",
  "neighbourhoodRef3": "Referencia Barrio 3",
  "TypeOfReference": "Tipo de Referencia",
  "NameOfReference": "Nombre de Referencia",
  "TelephoneOfReference": "Teléfono de Referencia",
  "AddressOfReference": "Dirección de Referencia",
  "EMICalculation": "Cálculo EMI",
  "EMIDuration": "Duración EMI",
  "EMIAmount": "Monto EMI",
  "FinancialInformation": "Información Financiera",
  "monthlySalesAmount": "Monto de Ventas Mensuales",
  "accountsPending": "Cuentas Pendientes (FIAO)",
  "inventory": "Inventario (Aprox.)",
  "MonthlyIncome": "Ingreso Mensual",
  "BasicInformation": "Información Básica",
  "ciCustomerSinceYears": "Cliente Proveedor Desde",
  "businessType": "Tipo de Negocio",
  "businessEstablishedSinceYears": "Negocio Establecido",
  "CalmadoTelephone": "Teléfono Colmado",
  "NameOfOwner": "Nombre del Dueño",
  "IDOfOwner": "Cédula del Dueño",
  "MobileNumberOfOwner": "Número Celular del Dueño",
  "ownerNumber":"Número Celular del Dueño",
  "ownerAddress": "Domicilio del Propietario",
  "ownerDateOfBirth": "Fecha de Nacimiento del Dueño",
  "ownerHomeType": "Su hogar el alquilado o propio?",
  "familyMembers": "Miembros de la familia (que viven con él)", // Family members (living with him)
  "numberOfVehicles": "Cantidad de Vehículos",
  "OwnerBankAccount":
    "Tiene una cuenta bancaria? De ser así, en cual banco?",
  "OnwerRemittance": "Recibe remesas? De ser así, de quien?",
  "adminDateOfBirth": "Fecha de Nacimiento del Administrador",
  "ApplicationHolder": "Titular de la Solicitud",
  "Expenses": "Gastos",
  "numberOfEmployees": "Cantidad de Empleados",
  "salaryOfEmployees": "Salario Empleado",
  "rent": "Alquilar",
  "electricity": "Luz",
  "telephone": "Teléfono",
  "CableInternet": "Cable/Internet",
  "GasOfDelivery": "Gasolina Para Delivery",
  "DebtsToSuppliers": "Deudas con Proveedores",
  "AdministrationInformation": "Información de Administración",
  "NameOfAdministration": "Nombre de la Administración",
  "adminId": "ID de la Administración",
  "adminNumber": "No. de Móvil de Administración",
  "adminSince": "Tiempo trabajando como Administrador",
  "adminAddress": "Dirección de Administrador",
  "adminSalary": "Salario de Administrador",
  "SiteVisit": "Visita al Local",
  "NoOfOwnedDeliveryMotors": "No. de Enseres y Equipos Propio Para Delivery",
  "NoOfExhibitionFridges": "No. de Neveras Exhibidoras",
  "NoOfFreezers": "No. de Freezers",
  "NoOfExhibitors": "No. de Exhibidores",
  "NoOfBotellero": "No. de Botelleros",
  "NoOfCars": "No. de Carros",
  "TypeOfLocal": "Tipo de Local",
  "NoOfTV": "No. de Televisores",
  "NoOfTelephone": "No. de Teléfono",
  "investor": "Tiene un inversor?",
  "roofType": "El techo esta hecho de Zinc, Madera o Concreto?",
  "Zinc": "Zinc",
  "Wood": "Madera",
  "Concrete": "Concreto",

  "Evidence": "Evidencia",
  "Invoice": "Factura",
  "INVOICE": "Factura",
  "PhotoID": "Identificación Fotográfica",
  "year": "Año",
  "LOCAL_PHOTO": "Foto Local",
  "ASSIGNED": "Asignado",
  "EXPIRED": "Expirado",
  "IN_PROGRESS": "En Progreso",
  "totalLoans": "Total de Préstamos",
  "generatedLoans": "Generados",
  "deniedLoans": "Denegados",
  "collectedLoans": "Cobrados",
  "pendingLoans": "Pendiente",
  "applicationId": "ID de Aplicación",
  "applicationId(S)": "ID de Aplicación(s)",
  "analystName": "Nombre del Analista",
  "operationOfficerName": "Nombre del Oficial de Operación",
  "Respectively": "respectivamente",
  //Work _History
  "AssingedMinimart": "Colmado Asignados",
  "generatedLoanAmount": "Monto del Préstamo Generado",
  "AssignedInvoicesAmount": "Importe de Factura Asignado",
  "AssignedInvoices": "Facturas Asignadas",
  "visitedMinimart": "Visitó Colmados",
  "depositedAmount": "Monto Depositada",
  "InTransitAmount": "Monto en Tránsito",
  "noOfMinimartContacted": "No de Colmados Contactados",
  "noOfRepaymentPlansGenerated": "No de Planes de Pago Generados",
  "noOfLoansAssigned": "No. de Préstamos Asignados",
  "INSUFFICIENT_LOAN_APPLICATION_DATA": "Datos insuficientes de la solicitud de préstamo.",

  //ConsolidatedMapView
  "consolidatedMap": "Mapa Consolidado",
  "discrepancyInAmount": "Hay Alguna Discrepancia en la Monto",
  "wantToContinue": "Desea continuar?",
  "VerifiedSuccessfully": "Verificado Exitosamente",
  "collectedAmount": "Monto Recaudado",
  "verifiedAmount": "Monto Verificada",
  "Clear": "Borrar",
  "VerifyAmount": "Verificar Monto",
  "SomeDiscrepency": "Alguna Discrepancia",
  "IsAmountCorrect": "¿El monto esta correcto?",
  "doYouWantToContinue": "¿Quieres Continuar?",
  "pleaseSelectReceipt": "Por Favor Seleccione el Recibo",
  "ErrorDepositNotAllowed": "Depósito No Permitido",
  "DepositId": "ID del Depósito",
  "ReceiptForDepositId": "Recibo de ID de Depósito",
  "StatusMustbeOther":
    "El Estatus de la acción debe ser no verificado",

  //Scheduler
  "Scheduler": "/listaDeConfiguración/Planificador",
  "CIDATA_SCHEDULER": "Planificador",
  "PleaseSelectValidPeriod": "Por favor seleccione período válido",
  "hours": "Horas",
  "minutes": "Minutos",
  "period": "Período",
  "ConfigurationManagement_CIDATA_SCHEDULER": "PLANIFICADOR",
  "hourMustBeNonZero": "La hora no puede ser cero",

  //Approval Dashboard
  "RepaymentPlan": "Plan de Pago",
  "ApprovalDashboard": "Panel de Aprobación",
  "loanStatus": "Estatus de Préstamo",
  "LoanCount": "Recuento de Préstamos",
  "InvoiceCount": "Recuento de Facturas",
  "InvoiceDate": "Fecha de la Factura",
  "LoanDate": "Fecha de Desembolso",
  "DisbursedAmount": "Monto Desembolsada",
  "CollectedAmount": "Monto Recaudado",
  "selectProvision": "Provisión",
  "loanList": "Lista de Préstamos",
  "loanCount": "Recuento de Préstamos",
  "Provison": "Provisión",
  "allTypesOfAmounts": "$ Pagado / $ Total / $ Desembolsado",
  "collections": "Colecciones",
  "webCollections": "Colecciones web",
  "Sales": "Ventas",
  "LoanPortFolio": "Cartera de Préstamos",
  "Installments": "Cuotas",
  "InvoiceAmount": "Monto de la Factura ($)",
  "loansGenerated": "Préstamos Generados",
  "assignedInvoices": "Facturas Asignadas",
  "assignedAmount": "Monto Asignada ($)",
  "fromInvoiceCount": "Del Recuento de Facturas",
  "toInvoiceCount": "A el Recuento de Facturas",
  "fromLoanCount": "De Recuento de Préstamos",
  "toLoanCount": "Al Recuento de Préstamos",
  "lowerAmount": "Menor Monto",
  "upperAmount": "Monto Superior",
  "FromAmount": "Menor Monto",
  "toAmount": "A Monto",
  "FromCountMustBeLess": "Desde cantidad debe ser menos",
  "FromAmountMustBeLess": "Desde monto debe ser menos",
  "Filters": "Filtros",
  "RestructuringStatus": "Estado de reestructuración",
  // Approve repayment plan
  "default": "Morosidad",
  "existing": "Existente",
  "requested": "Solicitado",
  "AssignRepayment": "Asignar Repago",
  "PleaseEnterSomething": "Por Favor Ingrese Algo",
  "EnterRequiredField": "Ingrese El Campo Requerido",
  "unPaidInterest": "Intereses Sin Pagar ($)",
  "InvalidNegotiationLateFees":
    "Negociación inválida cargos por atraso",
  "termCannotBeZero": "Plazo no puede ser cero",
  "ReportDetails": "Detalles del Informe",
  "Summary": "Resumen",
  "minimartCount": "Cantidad de Colmados",
  "SALES": "Ventas",
  "LOAN_PORTFOLIO": "Cartera_de_Préstamos",
  "COLLECTIONS": "Colecciones",
  "userRating": "Calificación de Usuario",
  "minimartUserRating": "Calificación del Usuario del Colmado",
  "totalUsers": "Usuarios Totales",
  "ratings": "Calificaciones",
  "pay": "Pagar",
  "payAction": "Acción de Pago",
  "unpaid": "No Pagado ($)",
  "paid": "Pagado ($)",
  "comission": "Comisión",
  "paymentDoneSuccessFully": "Pago Realizado Con éxito",
  "errorInPayment": "Error en el Pago",
  "zeroNotAllowed": "Cero no permitido",
  "amountToPayMustBeLessOrEqualToUnpaid":
    "Monto a pagar debe ser menor o igual al Monto no Pagado",
  "minimartDetailsText": "Detalles de Colmado",
  "creditLimitTooltip": "Total utilizado / Total asignado",
  "usedConsumerLimit": "Límite del Accesorios usado",
  "InvalidNegotiationInterest": "Interés de negociación inválido",
  "toBePaid": "A Ser Pagado ($)",

  //Special Messenger
  "Special": "Especial",
  "userCategory": "Categoría Usuario",
  // ***Approval Dashboard -->higher Credit limit
  "HigherCreditLimit": "Límite de Crédito Más Alto",
  "TypesofMinimart": "Tipos de Colmado",
  "READY_TO_APPROVE_CREDIT_LIMIT":
    "Listo para aprobar el límite de crédito",
  "READY_TO_DISBURSE": "Listo Para Desembolsar",
  "requestRejectedSuccessFully": "Solicitud rechazada con éxito",
  "requestApprovedSuccessFully": "Solicitud aprobada con éxito",
  "download": "Descargar",
  "document": "Documento",
  "documentId": "ID del Documento",
  "view": "Ver",
  "action": "Acción",
  "INPROCESS": "EN PROGRESO",
  "viewHigherCreditLimitRequest":
    "Ver solicitud de límite de crédito superior",
  "creditLimitAgreement":
    "Ver solicitud de límite de crédito más alto",
  "PleaseSelectMinimart": "Por favor seleccione Colmado",
  //credit score configuratio
  "creditScoreConfigurationList":
    "/listaDeConfiguración/puntajeDeCrédito",
  "ConfigurationManagement_COMMISSION_SCHEME": "ESQUEMA DE COMISIÓN",
  "ConfigurationManagement_CREDIT_SCORE": "PUNTUACIÓN DE CRÉDITO",
  "ConfigurationManagement_PROSPECT_CREDIT_SCORE":
    "PROSPECTO PUNTUACIÓN DE CRÉDITO",
  "combinedRating": "Calificación Combinada",
  "error": "Error",
  "addType": "Agregar Variable",
  "deleteType": "Borrar Tipo",
  "editSubtype": "Editar Subtipo",
  "editTypeWeight": "Editar Valor del Tipo",
  "editSubtypeWeight": "Editar Valor del Subtipo",
  "errorAllMappingsAreConfigured":
    "Todas las asignaciones están configuradas",
  "deleteSubTypeConfirmationMessage":
    "¿Quieres eliminar este subtipo?",
  "deleteTypeConfirmationMessage": "¿Quieres eliminar este Tipo?",
  "enterValueOf": "Ingrese el Valor de",
  "Detail": "Detalle",
  "errorPleaseSelectTheValue": "Por favor seleccione el valor",
  "errorDuplicateSelectionNotAllowed":
    "Selección duplicada no Permitida",
  "errorInvalidValue": "Entrada Inválida",
  "errorEmptyValue": "Error de Campo Vacío",
  "ErrorOptionSelection": "Por favor seleccione el tipo de reversión de pago.",
  "NO_ANY_DOWNPAYMENT_FOUND": "No se encontró ningún pago inicial para el día.",
  "BRANCH_ZONE_MAPPING_NOT_PRESENT": "Complete el mapeo de zonas de sucursales para el minimercado seleccionado : $",

  "errorStartValueShouldBeLessThanEndValue":
    "El valor inicial debe ser menor que el valor final",
  "errorPleaseSelectVariable": "Por favor seleccione variable",
  "DataIsNotValid": "Data no es Válida",
  "cancel": "Cancelar",
  "set": "Aceptar",
  "start": "Inicar",
  "end": "Final",
  "weight": "Valor",
  "creditScoreHeader": "CONFIGURACIONES DE PUNTUACIÓN DE CRÉDITO",
  "prospectcreditScoreHeader":
    "CONFIGURACIONES DE PROSPECTO PUNTUACIÓN DE CRÉDITO",
  "holidayHeader": "CONFIGURACIÓN DE VACACIONES",
  "CreditScore": "PUNTUACIÓN DE CRÉDITO",
  "CREDIT_SCORE": "PUNTUACIÓN DE CRÉDITO",
  "NewCreditScore": "PROSPECTO PUNTUACIÓN DE CRÉDITO",
  "PROSPECT_CREDIT_SCORE": "PROSPECTO PUNTUACIÓN DE CRÉDITO",
  "CHOOSE_TWO_DIFFERENT_MINIMARTS": "Elija dos minimercados diferentes para fusionar.",
  "GradeEWarning": "Información: Se elimina el grado E. Es de solo lectura para las configuraciones más antiguas.",
  //analyst
  "analystLoanView": "/vistaDePréstamoDeAnalista",
  "AnalystLoanDetails": "Detalles del Préstamo de Analista",
  "creditScore": "Puntaje de Crédito",
  "arePersonalReferencesSatisfactory":
    "¿Son satisfactorias las referencias personales?",
  "areCommercialReferencesSatisfactory":
    "¿Son satisfactorias las referencias comerciales?",
  "AnalystInput": "Analista de Entrada",
  "Negotiation": "Negociación",
  "references": "Referencias",
  "finacialRiskAssessment": "Evaluación de Riesgos Financieros",
  "Regulators": "Reguladores",
  "SelectMinimartRisk": "Riesgo del Colmado",
  "EnterClassification": "Clasificación",
  "EnterWorstArrearDays": "Ingrese Peores Días de Atrasos",
  "EnterLargestLaonAmount": "El monto de préstamo más grande",
  "validateCreditScore": "Enviar y calcular puntaje de crédito",
  "calculateEmi": "Calcular Cuota",
  "incomplete": "Incompleto",
  "approve": "Aprobar",
  "reject": "Rechazar",
  "selectClassification": "Clasificación",
  "highRisk": "Alto Riesgo",
  "mediumRisk": "Riesgo Medio",
  "lowRisk": "Riesgo Bajo",
  "CASH_TOPUP": "RECARGA DE EFECTIVO",
  "ErrorVersionCannotBeEmpty": "La versión no puede estar vacía",
  "pleaseSelectRequiredFields":
    "Por favor seleccione los campos obligatorios",
  "pleaseEnterNote": "Favor introduzca una nota",
  "OnlyTermsAllowded": "12,24 y 36 son solo términos permitidos",
  "submitNegotiation": "Enviar Negociación",
  "pleaseEnterValidNegotiationData": "Por favor, introduzca los datos de negociación válidos",
  "pleaseFirstSubmitNegotiation":
    "Favor envíe la negociación primero",
  "emiPeriod": "Período Cuota",
  "suggestedBiggestLoanAmount":
    "Monto de préstamo más grande sugerido",
  "selectTerms": "Términos",
  "appRejectedBecauseOfLowCreditScore":
    "Solicitud rechazada por bajo crédito score",
  "AssumingLoanDisbursesToday":
    "Asumiendo que el préstamo se desembolsa hoy",
  "bothReferencesAreMarkedNegative": "El préstamo será rechazado por",
  "doYouReallyWantToProceed":
    "1)referencias negativas 2) bajo punta de credit score, desea continuar?",
  "RejectNotification": "Préstamo Rechazo notificación",
  "WarningMinimartLocationMismatch":
    "La dirección de Colmado no coincide con la ubicación del check in del vendedor.",
  "negotiationChangesAreSaved":
    "Los cambios de negociación serán guardados",
  "analystInputsAreSaved": "Sntradas del analista son salvados",
  "low": "Bajo",
  "high": "Alto",
  "medium": "Medio",
  "AnalystApprovalDashboard": "Panel de Aprobación de Analistas",
  "INPROCESS_ANALYSIS": "Análisis en Proceso",
  "INPROCESS_OPERATIONS": "Operaciones en Proceso",
  "DeliveryReceiptIsVerified": "Recibo de Entrega es Verificado",
  "InputForDisbursement": "Entrada Para Desembolso",
  "AccountsOfficerIsInformedAboutDeliveryInstructions":
    "El oficial de cuentas está informado sobre las instrucciones de entrega",
  "DeliveryAppointmentConfirmedWithMinimart":
    "Cita de entrega confirmada con el colmado",
  "InputOfOperationsOfficer": "Entrada del Oficial de Operaciones",
  "failedApprovalOfOperationsOfficer":
    "Aprobación fallida del oficial de operaciones",
  "operationsInput": "Operaciones de Entrada",
  "AccountingOffficersChangesAreSaved":
    "Los cambios del oficial de contabilidad están guardados",
  "operationsOfficersChangesAreSaved":
    "Los cambios del oficial de operaciones están guardados",
  "IsCashDeliveryConfirmed":
    "Esta confirmado la Entrega del Efectivo?",
  "IsProductDeliveryConfirmed":
    "Esta confirmado la Entrega del Producto?",
  "InputOfAccountsOfficer": "Entrada del oficial de cuentas",
  "AccountingOfficerDashboard": "Tablero del Oficial de Cuentas",
  "INPROCESS_ACCOUNTS": "Cuentas en Proceso",
  "REJECTED_BY_ANALYST": "Rechazado por el Analista",
  "Disburse": "Desembolsar",
  "SignatureNotFound": "Firma de Solicitud de Préstamo no Encontrada",
  "OperationsOfficerDashboard":
    "Panel informativo del oficial de operaciones",
  "AccountsInput": "Entrada de Cuentas",
  "amountApproved": "Monto Aprobada",
  "InsufficientCreditLimit": "El límite de crédito es insuficiente",
  "loanHistoryRoutes": "/HistorialDePréstamos",
  "loanHistory": "Historial de Préstamos",
  "accountsOfficerLoandetails":
    "Detalles del préstamo del oficial de contabilidad",
  "operationsLoanDetails": "Detalles del préstamo de operaciones",
  "READY_TO_SIGN": "Listo Para Firma",
  "LoanRejectionWarningBecauseOfLowCreditScore":
    "Este préstamo será rechazado debido a su bajo puntaje de crédito",
  "Arrears_in_Days": "Atrasos en Días", //google Translated
  "Number_of_days_the_EMI_is_delayed": "Emi retrasada",

  // CI Wire Transfer
  "CIWireTransferTitle": "Transferencia Bancaria a CI",
  "GenerateReport": "Generar Reporte",
  "ReportFileName": "Reporte_de_transferencia_bancaria_de_CI",
  "TotalPaidAmount": "Monto Total Pagado",
  "TotalUnPaidAmount": "Monto Total  No Pagado",
  "PaidInvoiceLabel": "Factura Pagada",
  "UnPaidInvoiceLabel": "Factura Impaga",
  "ZoneId": "ID de la Zona",
  "visitDay": "Día de Visita",
  "pleaseSelectCheckboxesBeforeSubmit":
    "Favor seleccione las casillas para reenviar",
  "minimartBarredWarning": "y Colmado será obviado",
  "lowCreditScoreLoanRejectionWarning":
    "El préstamo será rechazado debido a la baja calificación crediticia",
  //Product Catalog
  "ProductCatalog": "Catalogo de Producto",
  "AddProduct": "Agregar Producto",
  "EditProduct": "Editar Producto",
  "AddEditCategory": "Agregar/Editar Categoría",
  "Add": "Agregar",
  "Update": "Actualizar",
  "selectCategory": "Categoría",
  "selectStatus": "Estatus",
  "product": "Producto",
  "model": "Modelo",
  "price": "Precio ($)",
  "cost": "Costo ($)",
  "ProductName": "Nombre del Producto",
  "Description": "Descripción",
  "ProductModel": "Modelo del Producto",
  "nameOfCategory": "Nombre de la Categoría",
  "CategoryModal": "Agregar / Editar Categoría",
  "InAcive": "Inactivo",
  "Category": "Categoría",
  "CategoryAlreadyExists": "La categoría ya existe",
  "productNotification": "Producto agregado / editado con éxito",
  "categoryNotification": "Categoría añadida / editada con éxito",
  "ProductAlreadyExists": "El producto ya existe",
  "waterConnection": "Conexión de agua",
  "APPROVED_minimart": "Colmado Aprobado con éxito",
  "REJECTED_minimart": "Colmado Rechazado con éxito",
  "INCOMPLETE_notification":
    "El Préstamo Está Marcado Como Incompleto",
  "READY_TO_DISBURSE_notification":
    "El préstamo está listo para desembolsar",
  "DISBURSED_notification": "préstamo desembolsado con éxito",
  "INPROCESS_ACCOUNTS_notification":
    "Préstamo asignado al oficial de cuentas",

  //Days
  "MONDAY": " Lunes",
  "TUESDAY": " Martes",
  "WEDNESDAY": " Miércoles",
  "THURSDAY": " Jueves",
  "FRIDAY": " Viernes",
  "SATURDAY": " Sábado",

  "LUN": "Lunes",
  "MAR": "Martes",
  "MIE": "Miércoles",
  "JUE": "Jueves",
  "VIE": "Viernes",

  //
  "loansAndInvoicesCount": "Préstamos Generados / Facturas Asignadas",
  "CommissionGeneratedAndPaid":
    "Comisión  Pagada / Comisión Generada ($)",
  "SingleUserCommissionDetails":
    "Detalles de comisión de un solo usuario",
  "commissionReport": "Reporte de Comisión",
  "generatedLoanCommission": "Comisión Generada de Préstamos",
  "generatedCollectionCommission": "Comisión Generada de Cobros",
  "commission": "Comisión",
  "Commission": "Comisión (%)",
  "commissionType": "Tipo de Comisión",
  "referenceNumber": "Número de Referencia",
  "paidCommission": "Comisión Pagada",
  "Collection": "Cobro",
  "LoanApprovalDashBoard": "Panel de Aprobación de Préstamos",
  "LOAN": "Préstamo",
  "COLLECTION": "Colección",
  "LoanRejectedDuetoLowGrade":
    "Préstamo Rechazado Debido a Bajo Grado",
  "CONSUMER": "CONVENIO",
  "LoanRejectedDueToNegativeReferences":
    "Préstamo Rechazado Debido por Referencias Negativas",
  "ProductRefId": "ID de Producto",
  "LoanGenerated": "Préstamo Generado",
  //Payment receipts
  "PaymentReceiptFileName": "Recibo_de_pago_para",
  //PaymentDenialReasons
  "paymentDenialReasons": "Razones de negar pago",
  "export": "Exportar",
  "ExportCallNotes": "Exportar Notas de Llamada",
  "denialReasons": "Razón Falta de Pago",
  "CallNotesReport": "Informe_de_notas_de_llamada",
  "reason": "Razón",
  "PAYMENT_NOTE": "NOTA DE PAGO",
  "PAYMENT_DENIAL_REASON": "RAZÓN DE NEGACIÓN DE PAGO",
  "exportReport": "Exportar de Reporte",
  "AnalystNote": "Nota de Analista",
  "referenceNotesbyAnalyst": "Notas de Referencia por el Analista",
  "financialRiskAssessmentNotesbyAnalyst": "Notas de Evaluación de Riesgo Financiero por el Analista",
  "regulatorsNotebyAnalyst": "Nota de Reguladores por el Analista",
  "author": "Autor",
  "supplierCreditLimit": "Límite de Crédito del Proveedor",
  "Close": "Cerrar",
  "receipt": "Recibo",
  "amountPaid": "Montos Pagada",
  "NoZones": "Sin Zonas",
  "CannotChangeAssignments": "Las asignaciones no se pueden cambiar",
  "pleaseEnterRequiredFields":
    "Por favor ingrese los campos requeridos",
  "DataNotUpdated": "Datos No Actualizados",
  "DataUpdatedSuccessfully": "Datos actualizados con éxito",
  "EditableFileds":
    "La dirección y el número de teléfono se pueden editar",
  "EditMinimartDetails": "Editar Detalles de Colmado",
  "EditLoanDetails": "Editar Detalles del Préstamo",
  "visitDays": "Días de Visita",
  "collectionDays": "Días de Recogida",

  "MobileNumberCannotBeLessthanTenDigits":
    "El número de móvil no puede tener menos de diez dígitos",
  "AddressCannotBeEmpty": "La dirección no puede estar vacía",
  "AssignedAndCollectedAmount":
    "Monto cobrado / Monto total asignado ($)",
  "ViewAmount": "Ver monto",
  "PhotoID_BACK": "ID de la Foto Atrás",
  "PHOTOID_BACK": "ID de la Foto Atrás",
  "PhotoID_FRONT": "ID de la Foto al Frente",
  "PHOTOID_FRONT": "ID de la Foto al Frente",
  "CREDIT_AGREEMENT_SIGNATURE": "Firma del Acuerdo de Crédito",
  "APPLICATION_SIGNATURE": "Firma de la Aplicación",
  "LOAN_AGREEMENT_SIGNATURE": "Firma del Acuerdo de Préstamo",

  "dt": "Fecha de Pago",
  "c": "Capital Pagado ($)",
  "i": "Pago Interesado ($)",
  "lf": "Cargo por Demora ($)",
  "ttl": "Monto Total ($)",
  "ix": "Índice",
  "Close Loan": "Cerrar Préstamo",
  "Loan Application Closed Successfully":
    "Solicitud de préstamo cerrada con éxito",
  "ConfirmCloseLoan": "Confirmar cierre de préstamo",
  "CannotCloseSameDateLoan":
    "La solicitud de préstamo no se puede cancelar el día de su creación.",
  "notVisited": "No visitado",

  //Commission
  "Sales Commission report": "Reporte de Comisión de Ventas",
  "Messenger Commission report": "Reporte de Comisión de Mensajeros",
  "Sales Moderator Commission report":
    "Reporte de Comisión de Moderador de Ventas",
  "Messenger Moderator Commission report":
    "Reporte de Comisión de Moderador de Mensajeros",

  "approvedAmount": "Monto Aprobado ($)",
  "approvedTerm": "Plazo Aprobado",
  "amountRequestedTitle": "Monto Solicitado",
  "approvedAmountTitle": "Monto Aprobado",

  "addHoliday": "Agregar Feriado",
  "editHoliday": "Editar Feriado",
  "day": "Día",
  "info": "Información",
  "HolidayInfo": "Información de Feriado",
  "sunday": "Domingo",
  "monday": "Lunes",
  "tuesday": "Martes",
  "wednesday": "Miercoles",
  "thursday": "Jueves",
  "friday": "Viernes",
  "saturday": "Sabados",
  "dateAlreadyExist": "Fecha ya Existe",
  "Interestrate": "Tasa de Interes",
  "pleaseEnterInformation": "Favor Ingrese la Información",
  "unexperienced": "SE(sin experiencia)",
  "MINIMART_LIST_REPORT": "Reporte_de_lista_de_colmado",
  "MINIMART_NOTES_REPORT": "Informe_de_notas_del_colmado",
  "LOAN_NOTES_REPORT": "Informe_de_notas_del_prestamo",
  "DeleteEvidence": "Eliminar Evidencia",
  "DeleteEvidenceMessage":
    "¿Estás seguro de que quieres eliminar las evidencias?",
  "TotalBalanceAmount": "Nueva Equilibrio",
  "New EMI": "Nueva EMI",
  "SelectAnalyst": "Analista",
  "NEGOTIATION_EXPIRED_MESSAGE":
    "Esta solicitud caducó, envíe una nueva solicitud",

  //New minimart approval
  "MinimartApproval": "Colmado Aprobado",
  "ApprovalReminders": "Recordatorios",
  "ApprovalRequests": "Solicitudes",
  "minimartApprovalRequests": "/solicitudDeAprobacionParaColmado",
  "MinimartApprovalRequests": "Solicitud de Aprobacion Para Colmado",
  "adminIDMustBeElevanDigits": "El ID de administrador debe tener 11 dígitos",
  "alternateNumberMustBeTenDigits": "El número de móvil alternativo debe tener 10 dígitos.",

  "MobileNumberMustBeTenDigits":
    "El numero de telefono debe tener minimo 10 digitos",
  "InvalidZoneName":"Por favor ingrese un nombre de zona válido.",
  "CedulaIdMustBeElevenDigits":
    "El numero de cedula debe tener once digitos",
  "NewCIMinimart": "Nuevo CI Minimart",
  // Approval Minimart History
  "minimartHistory": "Historial de Aprobacion",
  "selectMinimartStatus": "Estatus del Colmado",
  "AlternateNumber": "Numero Alternativo",
  "adminName": "Numero del Administrados",
  "ownerCedulaNumber": "Numero de Cedula del Propietario",
  "minimartRaisedDate": "Solicitud de Aprobacion Enviada",
  "minimartStatus": "Estatus del colmado",
  "ownerDetails": "Detalles del Propirtario",
  "approveDetails": "Detalles de Aprobacion",
  "approvedRequest": "Solicitud de Aprobacion",
  "rejectedRequest": "Solicitud Rechazada el",
  "selectDay": "Elija el Dia",
  "pleaseSelectDay": "Porfavor Elija el Dia",
  "EditOwnerDetails": "Editar Informacion del Propietario",
  "REJECT_notification": "Préstamo Rechazado con Éxito",
  "APPROVE_notification": "Préstamo Aprobado con Éxito",
  "REJECTED_notification": "Préstamo Rechazado con Éxito",
  "APPROVED_notification": "Préstamo Aprobado con Éxito",
  "APPROVE_WITH_CONCILIATION_notification":
    "Aprobado Exitosamente Con Préstamo De Conciliación",

  "MatricesID": "Codigo de Matrices",
  "id": "Codigo de Matrices",

  "viewLoans": "Ver Prestamos",
  "checkLoanApplication": `Este negocio tambien aplico para un prestamo. Desea visitar el panel de aprobacion? `,
  "applicationId(s)": "Codigo de apricacion(s) : ",

  "externalFactoring": "Facturas de Otro Suplidor",
  "tuPrestamo": "Tu Primer Prestamo",
  "EXTERNAL_FACTORING": "FACTURAS DE OTRO SUPLIDOR",
  "TU_PRESTAMO": "TU PRIMER PRESTAMO",
  "SupplierName": "Nombre del Suplidor",
  "externalInvoice": "Factura Externa",

  "allMinimarts": "Tipo de Proveedor",
  "CIMinimarts": "CI Colmados",
  "otherMinimarts": "Otros Negocios",
  "ticketId": "Codigo del Ticket",
  "CONFIRMED": "Confirmado",
  "confirmedOn": "Peticion Confirmada",
  "approvedBy": "Aprobado por",

  //Motorcycle Catalogue
  "motorcycleCatalogueTitleText": "Catálogo de Enseres y Equipos",
  "MotorcycleCatalogue": "/catálogoDeEnseresYEquipos",
  "AddMotorcycle": "Agregar Enseres y Equipos",
  "motorcycleName": "Nombre de Enseres y Equipos",
  "PleaseAddImage": "Por favor agregar imagen.",
  "CostMustBeNumeric": "Costo debe de ser números.",
  "Personal": "Personal",
  "Carrier": "Transportador",
  "Electric": "Eléctric",
  "Gas": "Gas",
  "MActive": "Activo",
  "MInActive": "Inactivo",
  "motorcycleLoan": "Enseres y Equipos",
  "MotorcycleDetails": "Detalles del Enseres y Equipos",
  "EditMotorcycleDetails": "Editar Detalles del Enseres y Equipos",
  "AddMotorcycleDetails": "Agregar Detalles del Enseres y Equipos",
  "UploadPhoto": "Subir fotos",
  "NoPhoto": "Sin fotos",
  "NoDescription": "Sin Descripción",
  "MotorcycleAddedSuccessfully": "Enseres y Equipos añadida exitosamente.",
  "Motorcycle": "Enseres y Equipos",
  "invalidLoanAmount": "El monto del prestamo es invalido",
  "Minimart": "Colmado",
  "remindedMinimartCount": "Número de Recordatorios de Colmados",
  "supplierLogo":"Logotipo del Proveedor",
  "productCost": "Valor de Producto ($) ",
  "collectedDownpayment": "Inicial Recolectado",
  "expectedDownpayment": "Inicial Esperado ($) ",
  "downpaymentReceipt": "Recibo del Inicial",
  "READY_FOR_COLLECTION": "Listo para Colección",
  "MessengerNote": "Nota de Mensajero",

  //Salesperson efficiency dashboard
  "SalespersonEfficiencyDashboard":
    "Dashboard de Eficiencia del Vendedor",
  "PenetrationEffectiveness": "Eficiencia de Penetración (%)",
  "ConversionRate": "Tasa de Conversión (%)",
  "SalespersonEfficicencyReport":
    "Reporte de Eficiencia del Vendedor",
  // Single Sales person  efficiency dashboard
  "singleSalesPerson": "/panelDeVentas/ventasUsuario",
  "disbursementEfficiency": "Eficiencia de Desembolso (%)",
  "penetrationEffectiveness": "Eficiencia de Penetración (%)",
  "conversionRate": "Tasa de Conversión (%)",

  "loanAgreement": "Acuerdo de Préstamo",
  "route": "Ruta",
  "selectRouteDays": "Seleccionar días de ruta",
  "collectionModNotes": "Notas del Moderador de la Colección",
  "inProcess": "En Proceso",

  //CR - Business Type
  "BusinessType": "Tipo de Negocio",
  "Colmados": "Colmados",
  "Cafeterias": "Cafeterias",
  "ComedoresEconómicos": "Comedores económicos",
  "Salon": "Salon",
  "Barberia": "Barberia",
  "PequenoSupermercado": "Pequeno Supermercado",
  "Gomeras": "Gomeras",

  "blockedMinimartWarning": "Este negocio esta bloqueado",
  "askToBlockTheMinimart": "Desea bloquear el negocio?",
  "askToUnlockTheMinimart": "Desea desbloquear el negocio?",
  "BLOCK": "BLOQUEAR",
  "BLOCKED": "BLOQUEADO",
  "UNBLOCK": "DESBLOQUEADO",
  "minimartStatusUpdatedSuccessfully": "Estado de Minimart actualizado con éxito",

  "SelectBranchToApprove": "Seleccione Sucursal para aprobar",
  "supervisor": "Supervisor",
  "selectSalesModerator": "Seleccione Moderador de Ventas",
  "selectFileStatus": "Estatus de Archivo",
  "viewNote": "Ver Notas",
  "viewOrAddNote":"Ver o Agregar Nota",
  "noteId": "Notas",
  "errorEmptyNote": "Por favor agregue una nota",

  "RENTED": "ALQUILADO",
  "OWNED": "PROPIO",
  "MOTORCYCLE": "ENSERES Y EQUIPOS",
  "DELETE": "BORRAR",
  "OWNER": "DUENO",
  "resetGradeText": "Esta seguro de querer resetearle el grado?",
  "RANGE": "RANGO",
  "YES_NO": "Si o No",
  "MULTI_VALUE": "VALOR MULTIPLE",
  "imageUploadFailed":
    "La Imagen no se cargo, por favor intente de nuevo.",
  "sizeIsTooLarge":
    "El Archivo es muy grande. El tamano mas grande permitido es de 5 MB.",
  "fileExtension":
    "Solo se permiten los siguientes formatos de archivo: png, jpg, jpeg.",
  "fileExtensionErrorOfTranferReceipt":
    "Solo se permiten los siguientes formatos de archivo: png, jpg, jpeg, pdf.",


  //MTP
  "MTPApproval": "Aprobado",
  "MTPComplaintPortal": "Portal de Quejas",
  "MTPDashboard": "T Presta Panel",
  "MTPLadder": "Escalera",
  "MTPProducts": "Productos",
  "MTPPromotions": "Promociones",
  "MTPLoanRequests": "Solicitudes de Préstamo", //
  "MTPSignupRequests": "Solicitudes de Registro", //
  "MTPEditMinimrtRequests": "Solicitud de Minimercado", //
  "MTPPromotionsApproval": "Aprobación de Promoción",

  "mtp": "Scala",
  "catalog": "Catálogo",
  "work": "Optiones de Trabajo",
  "ci": "César Iglesias",
  "management": "Gerencial",

  "NewFeatureComingSoon": "Esta opcion esta en desarrollo",
  "featureBlocked": "Esta opción está bloqueada.",

  "productName": "Nombre del Producto",
  "uptoAmount": "Aplica Esta Este Monto",
  "promotionName": "Nombre del Promoción",
  "editedBy": "Editado por",
  //temp product strings
  "BUSINESS_IMPROVEMENT": "MEJORA DE NEGOCIO",
  "CAR": "CARRO",
  "PROPERTY": "PROPIEDAD",
  //end

  "Products": "Productos",
  "Promotions": "Promocións",
  "rewardPoints": "Puntos de Recompensa",
  "discount": "Descuento",

  "AddNewPromotion": "Agregar nueva promocion",
  "activationDate": "Fecha de Activación",
  "ExpiryDate": "Fecha de Vencimiento",
  "requiredGrade": "Grado Requerido",
  "requiredLevel": "Nivel Requerido",

  "editedAt": "Editado en la fecha | a las",
  "invalidPromotionLevel": "Nivel debería de ser entre 1-7",
  "invalidDateRange": "Rango de Fecha Inválido",
  // #endregion

  //Levels
  "LEVEL1": "NIVEL 1",
  "LEVEL2": "NIVEL 2",
  "LEVEL3": "NIVEL 3",
  "LEVEL4": "NIVEL 4",
  "LEVEL5": "NIVEL 5",
  "LEVEL6": "NIVEL 6",
  "LEVEL7": "NIVEL 7",

  "invalidTerms": "Duración inválida",
  "duplicateTerms": "Duración duplicada",
  "Factoring": "Facturas CI",
  "ErrorDaysRangeShouldStartWithOne":
    "El rango de cantidad debe comenzar con uno",
  "ConfigurationManagement_LATE_FEES":
    "CARGOS POR PORCENTAJE DE MORA",
  "resetGrade": "Restablecer la calificación",
  "costInvalid": "El costo no puede ser cero",
  "durationTitle":
    "Aceptamos múltiples términos. Puede agregar términos presionando la tecla ENTER.",
  "pleaseEnterCorrectAmount":
    "El monto ingresado es mayor que el monto solicitado. Introduzca el monto correcto.",
  "EvidencesNotes": "Notas de Evidencia del Analista",
  "SalesPersonNotes":"Notas del vendedor",
  "seeMinimartDetails": "Ver Detalles del Negocio",

  "registrationStatus": "Estado de Registro",
  "PENDING": "Pendiente",
  "minimartRejectionNote": "Notas de Rechazo del Analista",
  "totalOutstandingError":
    "El negocio tiene # activo (#) con una deuda total de #.",
  "approvedLoanPresentError": "Esta empresa ha aprobado préstamo(s) (#) con una deuda de #",
  "wrongLoanCombinationError":
    "El préstamo solicitado no se puede combinar con los siguientes ID de préstamos activos: ", // Requested loan cannot be combined with following active loans:
  "multipleActiveLoansError":
    "Se alcanzó el límite de préstamos activos. Los siguientes son los préstamos activos: ", // Active loans limit reached. Following are the active loans:

  // Remittance statuses
  "SCHEDULE": "Programar",
  "RESCHEDULE": "Reprogramar",
  "DELIVERED": "Entregado",
  "IN_PROCESS": "En Proceso",
  "IN_ROUTE": "En Ruta",
  "CANCELLED": "Cancelada",

  "remittanceStatus": "Estado de Remesa",
  "remittanceDeliveryReceipt": "Recibo de Entrega de Remesa",
  "deliveryDate": "Fecha de Entrega",
  "deliveryTime": "Hora de Entrega",
  "deliveryMessage": "Mensaje de Entrega",
  "remittanceIsPending":
    "El Estatus de Entrega de la Remesa es desconocido, quisiera desembolsar el préstamo manualmente?",
  "loanDisbursement": "Desembolso de Préstamo",
  "validDateTimeError":
    "Por favor introduzca una fecha y hora válida para la Entrega.",
  "Devilery Receipt": "Recibo de Entrega",
  "failureReason": "Razón de la Falla : ",
  "amortizationDataError":
    "No se puede encontrar la data de Amortización.",
  "confirmMessage": "¿Desea continuar?",
  "verifyCollectionAmountError": "Introduzca el monto correcto",
  "mobileNumberValidation":
    "El número móvil debe de tener 10 dígitos",
  "telephoneNumberValidation":
    "El número de teléfono debe de tener 10 dígitos",

  "minimartLevel": "Nivel de Negocio",
  "FOOD": "Alimentos",
  "DETERGENT": "Detergentes",
  "InvoiceType": "Tipo de Factura",
  "remittanceDelivery": "Entrega de Remesas",
  "remittanceDeliveryMessage":
    "Entrega de Remesa para ID de Solicitud #",

  "ICL_ERROR_MSG":
    "Este préstamo será rechazado debido a un límite de crédito insuficiente y los ID de préstamos son: ",
  "ICL_opr_off_msg":
    "Este préstamo tiene límite de crédito insuficiente. ID de préstamos activos : ",

  "typeOfReference": "Tipo de Referencia",
  "phone": "Teléfono de Referencia",

  "confirmRemittanceDelivery": "Confirmar envió de Remesas",
  "confirmRemittanceAmount": "Si no, introduzca el monto correcto :",

  "activeMinimart": "Negocio Activo al Día / Asignación Permanente",

  "RemittaneAmountError":
    "El monto de la remesa no debe ser mayor que el monto aprobado.",
  "ZeroAmountError":
    "El monto de la Remesa no puede ser negativo ni cero.",

  "fastCash": "Préstmo Express",
  "FAST_CASH": "PRÉSTMO EXPRESS",
  "REJECTED_BY_OPERATIONS_OFFICER":
    "Rechazado por Oficial de Operaciones",
  "remittanceDeliveryPopupMessage":
    "Estamos realizando la remesa por un monto de $# el ",
  "minimumAmount": "Monto mínimo",
  "maximumAmount": "Monto máximo",

  //MTP Complaint Management
  "complaintPortal": "Portal de Denuncias",
  "complaintHistory": "Historial de Denuncias",
  "complaint": "Denuncia",
  "reportedBy": "Reportado por",
  "reportedAt": "Reportado en",
  "resolve": "Resolver",
  "resolvedBy": "Resuelto por",
  "resolvedSuccesfully": "Reclamación resuelta con éxito", //
  "COMPLAINT_1": "No paso el vendedor.",
  "COMPLAINT_2": "No paso el cobrador.",
  "COMPLAINT_3": "No me entregaron recibo de pago.",
  "COMPLAINT_4": "No me entregaron ticket del financiamiento.",
  "COMPLAINT_5": "No me puedo comunicar con Matrices.",
  "COMPLAINT_6": "Quiero informacion sobre mis prestamos.",
  "COMPLAINT_7": "La aplicacion me da un error.",
  "OTHER": "Otras",

  "wallet": "Cartera",
  "WALLET": "Cartera",
  "walletReports": "Reporte de Cartera",
  "disbursementReport": "Reporte de Desembolsos",
  "totalDisbursementAmount": "$ Monto Total de Desembolsos",
  "totalDueAmount": "$ Capital  / $ Intereses / $ Mora",
  "totalPastDueAmount": "$ Monto Total Vencido",
  "profit": "$ Ingreso",

  "rescheduledLoanDetails":
    "Detalles de Restructuración de Préstamos",
  "rescheduledLoanError":
    "El monto introducido de cuota y plazo no cubre el monto de saldo vencido.",
  "resetGradeSuccess": "Grado resetado exitosamente",

  //MTP Pending Minimarts
  "dismissedBy": "Desestimar por",
  "dismissedAt": "Desestimar en",
  "VISITED": "Visitó",
  "Request loan": "Pedir prestamo", //
  "deletedSuccessfully": "Borrado exitosamente", //

  //salesperson assignment
  "assignSalesPerson": "Cuando quiere que se le asgine un vendedor?",
  "assignMessenger": "Quieres asignar una mensajera?",
  "salesPersonAssignedSuccess": "Vendedor asignado exitosamente",
  "messengerAssignedSuccess": "Mensajera asignado exitosamente.",
  "salesPersonAssignment": "Asignación de Vendedor",
  "messengerAssignment": "Asignación de Mensajero",
  "visitNote":
    "Nota: Si mañana es Sábado o Domingo, la visita será programada para el Lunes.",
  "Today": "Hoy",
  "Tomorrow": "Mañana",
  "PERMANENT_ASSIGNMENT_NOT_FOUND":
    "No se pudo encontrar la asignación de usuario permanente para el minimart solicitado.",
  "SALESPERSON_ALREADY_ASSIGNED":
    "El vendedor ya está asignado para hoy.",
  "SALESPERSONS_VISIT_IS_ALREADY_PLANNED":
    "La visita del vendedor ya está programada para mañana.",
  "HOLIDAY_ERROR":
    "¡Lo siento! No podemos procesar su solicitud hoy debido a un dia feriado.",
  "HOLIDAY_ASSIGNMENT_ERROR":
    "Mañana es un día festivo, el usuario no pudo ser asignado.",

  "promotionImage": "Imagen de Promoción",
  "REMITTANCE_PAYMENT": "PAGO DE REMESAS",
  "remittanceAmount": "Monto de Remesa ($)",
  "remittanceDeliveredDate": "Fecha de Delivery de Remesa",
  "remittanceRaisedDate": "Fecha de Emisión de la Remesa",
  "remittanceID": "ID Remesa",
  "paymentStatus": "Estatus de Pago",
  "UNPAID": "No Pagado",
  "selectPaymentStatus": "Seleccione Modo de Pago",
  "remittanceMinimumAmount": "Monto Mínimo de Remesa ($)",
  "remittanceMaximumAmount": "Monto Máximo de Remesa ($)",
  "remittanceMinAmountError":
    "Por favor introduzca el monto mínimo de la remesa",
  "remittanceMaxAmountError":
    "Por favor introduzca el monto máximo de la remesa",
  "minMaxError":
    "El Monto Mínimo de la Remesa debe ser menor que el Monto Máximo de la Remesa",
  "conciliationDetails": "Detalles de Conciliación",
  "conciliationDate": "Fecha de Conciliación",
  "conciliationBy": "Conciliación Por",
  "conciliationByApplicationID": "Conciliación por ID de aplicación",
  "selectConciliation": "Seleccione Conciliación",
  "CONCILIATED": "Conciliación",
  "NON_CONCILIATED": "No Conciliado",
  "LoansToConciliateWith": "Préstamos a Conciliar Con",
  "closedByConciliation": "Cerrado por Conciliación",
  "closedByRestructuring": "Cerrado por Restructuración",
  "createdByRestructuring": "Creado por Restructuración",
  "viewMoreDetails": "Ver más Detalles",
  "remittanceAddress": "Dirección de Remesa",
  "remittanceTelNumber": "Número telefónico de Remesa",
  "telephoneNumber": "Introduzca el Número de Teléfono",
  "fundTransfer": "Transferencia de Fondos",
  "INTERNAL_SERVER_ERROR": "Error Interno del Servidor",
  "REMITTANCE_TIMEOUT_ERROR":
    "No se puede acceder al Servidor Central de Remesas",
  "loanReminder": "Recordatorio de Préstamo",
  "reminderStatus": "Estado del Recordatorio",

  "LoanSummary": "Resumen del Préstamo",
  "ndt": "Fecha de Vencimiento Siguiente",
  "uc": "Capital No Pagado",
  "ui": "Intereses No Pagados",
  "ulf": "Cargos por Pago Atrasado",
  "atc": "Monto a Cobrar",
  "total": "Total ($) ",

  "pds": "Fechas de Pago",
  "pdb": "Pago Retrasado Por",
  "dpd": "Días de Pago Retrasado",
  "createdAt": "Creado en", //
  "OperationsOfficerNote": "Nota de Oficial de Operaciones", //

  "actionTakenBy": "Acción Tomada Por",
  "actionTakenOn": "Acción Tomada En",
  "salesPersonPositionNotFilledRoute":
    "El puesto de vendedor no está ocupado para la ruta. Asigne el vendedor a la zona.",

  //New Minimart
  "NewMinimart": "Prospecto Colmado",
  "newMinimArtManagement": "Gestión de Prospecto Colmado",

  "assignedSalesPerson": "Vendedor Asignado",
  "assignedMessenger": "Mensajera Asignada",

  "arrearInstallments": "Cuotas Atrasadas",

  "minimartRejectedNotification": "Colmado Rechazado con Éxito",
  "minimartApprovedNotification": "Colmado Aprobado con Éxito",

  "invalidLateFeesRequest":
    "Solicitud de despegue de cargos por pagos atrasados ​no válida. Los cargos por pago atrasado actuales son menores que los cargos por pago atrasado aprobados. ¡Descartando esta solicitud!",
  "HigherCreditLimitErroMessage":
    "Los límites de crédito deben estar por debajo de los siguientes niveles", //
  "LowerCreditLimitErrorMessage":
    "Los límites de crédito deben ser más altos que los niveles anteriores", //
  "ErrorAmountShouldStartWithOne":
    "El rango de cantidad debe comenzar con uno",
  "INVALID_AMOUNT":
    "Pagar una cantidad del 50% del préstamo activo de recarga de efectivo para obtener el nuevo.", //
  "ZonePositionMapping": "Mapeo De Posicion De Zona",
  "ExistingSalesPersonUser": "Usuario de Vendedor Existente",
  "ExistingMessengerUser": "Usuario de Mensajero Existente",
  "NewSalesPersonUser": "Nuevo usuario de Vendedor",
  "NewMessengerUser": "Nuevo usuario de Mensajero",
  "None": "Ninguno",
  "ChangePosition": "Cambiar de Posición", //
  "salesPosition":
    "El puesto de vendedor $ estará vacante. Por favor, confirme.", //
  "messengerPosition":
    "El mensajero Posición $ estará vacante. Por favor, confirme.", //
  "salesPositionAssign":
    "El vendedor $ será desactivado. El nuevo vendedor $ será asignado en la posición $. Por favor, confirme.", //
  "messengerPositionAssign":
    "El mensajero $ será desactivado. El nuevo mensajero $ se asignará en la posición $. Por favor, confirme.", //
  "salesSwapPosition":
    "La posición de $ se intercambiará con la carga de trabajo de $. Por favor, confirme.", //
  "messengerSwapPosition":
    "La posición de $ se intercambiará con la carga de trabajo de $. Por favor, confirme.", //
  "pleaseSelectUser": "Seleccione el usuario.", //
  "assignmentDoneSuccessfully": "Asignación realizada con éxito.", //
  "assignmentFailed": "Error en la asignación.", //
  "searchedZones": "Lista de zonas buscadas: ", //
  "Terms": "Términos", //
  "OtherTerms": "Otros Terminos", //
  "POSITION_NOT_FOUND": "Posición no encontrada", //
  "AddPosition": "Agregar Nueva Posición", //
  "POSITION_NAME_ALREADY_PRESENT":
    "¡El nombre de la posición ya está presente en el sistema! Ingrese un nombre de posición diferente.", //

  "messengerPositionText": "Posición del Mensajero", //
  "salesPersonPostion": "Posición del Vendedor", //
  "BRANCH_ZONE_MAPPING_NOT_FOUND":
    "Mapeo de zona de sucursal no encontrado.", //
  "ROUTE_NOT_FOUND": "Ruta no encontrada.", //
  "positionNameLengthError":
    "El nombre de la posición debe tener de 5 a 7 caracteres", //
  "NO_MESSENGER_FOUND_FOR_NEW_MESSENGER_POSITION":
    "No se encontró ningún mensajero para la nueva posición de mensajero.",
  "NO_MESSENGER_FOUND_IN_THE_ZONE":
    "Ninguna mensajera encontrada en la zona.",
  "positionChanged": "La posición cambió con éxito.",
  "positionChangedAssignmentsNotDone": "La posición cambió con éxito. Los cambios se reflejarán en unos minutos.",
  "addPosition": "El puesto se agregó con éxito.",
  "NO_WORKLOAD_FOR_POSITION":
    "El puesto $ de la zona $ no tiene carga de trabajo extra.",
  "editPosition": "Editar Posición",
  "NO_MESSENGER":
    "Sin asignación de mensajero para la zona $ de la posición $",
  "INVALID_CREDIT_LIMIT_AMOUNT":
    "El monto solicitado debe ser mayor al monto existente",
  "salesVacantPositionAssign":
    "El nuevo vendedor $ se asignará a la posición $. Por favor confirmar.", //
  "messengerVacantPositionAssign":
    "El Nuevo Mensajero $ se asignará en la posición $. Por favor confirmar.", //
  "USE_WORKLOAD_MANAGEMENT_ERROR":
    "No se puede desactivar el usuario de messenger desde aquí, utilice la gestión de carga de trabajo.", //
  "MESSENGER_POSITION_NOT_FILLED_FOR_ROUTE":
    "Puesto de mensajero no ocupado para la ruta.", //
  "addZone": "Agregar Nueva Zona",
  "addedZone": "La zona se agregó con éxito.",

  //MTP SIGN UP
  "signupRequestDetails": "Solicitud de Registro Detalles", //
  "selection": "Selección", //
  "matchingMinimartDetails": "Detalles Colmado a Juego", //
  "EditAssociation": "Editar asociación", //
  "ExistingMinimart": "Seleccione un colmado existente.", //
  "MinimartExisting": "Colmado existente seleccionado.", //
  "NOTE_NOT_FOUND": "Nota no encontrada.", //
  "REQUEST_NOT_FOUND": "Solicitud no encontrada.", //
  "MINIMART_NOT_FOUND": "Colmado no encontrado.", //
  "StopAssignment": "Detener asignación", //
  "StopAssignmentSuccessfully": "Detener la asignación con éxito.", //
  "matchType": "Tipo de Concordancia", //
  "CODE": "Coincidencia de código", //
  "NAME": "Coincidencia de nombres", //
  "OWNERID": "Coincidencia de ID de propietario", //
  "ADDRESS": "Coincidencia de direcciones", //
  "existingMinimartDetails": "Detalles de Minimart existentes", //
  "newMinimartDetails": "Nuevos detalles Minimart", //
  "approvalNote": "Nota de aprobación", //
  "rejectionNote": "Nota de rechazo", //
  "NoteDetails":"Detalles de la Nota",
  "Optional": "Opcional", //
  "EXISTING": "Existente",
  "minimartDetailsUpdate":
    "Los detalles de colmado se actualizaron con éxito.", //
  "selectMinimartGrade": "Seleccionar Grado Colmado", //
  "selectMinimartType": "Seleccionar Tipo Colmado", //
  "selectMinimartLevel": "Seleccionar Nivel de Colmado", //
  "largetPercentage": "Mayor Porcentaje (%)", //
  "loanAmountLimit": "Monto del préstamo",
  "pleaseSelectGrade": "Por favor seleccione grado.", //
  "pleaseSelectType": "Por favor seleccione tipo.", //
  "pleaseSelectGradeType": "Seleccione el grado y el tipo.", //
  "remittanceError":
    "¡La solicitud de aumento de la remesa ha fallado debido a que no se puede acceder al servidor central! Desembolse este préstamo manualmente.",
  "ANALYSED_BY_SYSTEM": "Analizado Por Sistema",
  "InstallmentArrearsFrom": "Cuotas en atrasos (Desde)", //
  "InstallmentArrearsTo": "Cuotas en atrasos (Hasta)", //

  "homeLocation": "Localización de la Casa", //
  "socialMediaAccountDetails":
    "Detalles de Cuentas de Redes Sociales", //
  "bankDetail": "Detalles del Banco",
  "CIZone": "CI Zona",
  "Mother": "Madre", //
  "Husband": "Esposo", //
  "invalidRange": "Rango de entrada no válido", //
  "conciliationApprove": "Aprobación de Conciliación", //
  "conciliationWarning":
    "Refierase al prestamo anterior ID $ para ver los detalles de la deuda.", //
  "conciliationAccess": "Acceso a Conciliación", //
  "conciliationDeatails": "Detalles de Conciliación", //
  "unpaidCapital": "Capital No Pagado ($)", //
  "totalDebt": "Deuda total ($)", //
  "atmAmount": "Monto de Remesa ($)", //
  "editConciliationDeatails": "Editar Detalles de conciliación", //
  "unpaidLateFees": "Cargos por Pago Atrasado ($)", //
  "conciliationInterest": "Interés de Conciliación", //
  "conciliationLateFees": "Cargos por mora de Conciliación", //
  "conciliationHistory": "Historia de la Conciliación", //
  "ssn":
    "Este préstamo es creado por una persona especial de ventas $.",
  "loanPayOffText": "¿Quieres liquidar este préstamo?", //
  "conciliationError":
    "El monto aprobado debe ser mayor que la deuda total impaga del préstamo anterior.", //
  "SpecialSales": "Ventas Especiales", //
  "specialCharError":
    "No se permiten caracteres especiales en el nombre.",
  "validUnpaidInterestError":
    "Ingrese un monto de interés no pagado válido.",
  "pdfDownload":
    "Por favor revise su correo electrónico para descargar PDF/CSV.",
  "tempPasswordSendToMobileNumber": "La contraseña temporal se enviará a",
  "conciliatedLoanDetailsFor": "Detalles del Préstamo de Conciliación para",
  "restructureDetailsFor": "Detalles del Préstamo de Reestructurar para",
  "CreatedLoanDetails": "Detalles del préstamo creado después de la reestructuración  ",
  "ClosedLoanDetails": "Detalles del préstamo cerrado tras la reestructuración  ",
  "ClosedRestructuredLoan": "Préstamo Reestructurado Cerrado",
  "CreatedRestructuredLoan": "Préstamo Reestructurado Creado",
  "LoanCreatedByRestructuring": "Préstamo generado tras reestructuración $ ",
  "LoanClosedByRestructuring": "Este prést fue reestructurado $",
  //MTP PROMOTIONS
  "promotionStatus": "Estado de la Promoción",
  "promotionType": "Tipo de promoción",
  "INTERNAL": "Itenrno",
  "EXTERNAL": "Externo",
  "productType": "Tipo de Producto",
  "benefitType": "Tipo de Beneficio",
  "DISCOUNT": "Descuento",
  "min": "Mínimo",
  "max": "Máximo",
  "rewardPointsRange":
    "Ingrese el rango de puntos de recompensa válidos.",
  "INSTALLMENT": "Cuotas",
  "REWARDS": "Puntos de Recompensa",
  "P_ACTIVE": "Activo",
  "P_INACTIVE": "Inactivo",
  "P_NEW": "Nuevo",
  "P_EXPIRED": "Expirado",
  "P_CASH_TOPUP": "Recarga de Efectivo",
  "P_CONSUMER": "Convenio",
  "P_TU_PRESTAMO": "Tu Primer Prestamo",
  "P_MOTORCYCLE": "Enseres y Equipos",
  "P_FAST_CASH": "Préstmo Express",
  "P_FACTORING": "Facturas CI",
  "P_EXTERNAL_FACTORING": "Facturas de Otro Suplidor",

  // MTP Promotion Interest
  "promotionDetails": "Detalles de la Promoción",
  "promotionCount": "Cantidad de Promoción",
  "promotionAmount": "Importe de la Promoción",
  "installmentIds": "ID de Cuotas",

  "INVALID_AMOUNT_ON_PROMOTION":
    "La cantidad solicitada no es válida para este préstamo debido a la promoción.",
  "APPROVED_BY_SYSTEM": "Aprobado por el Sistema",
  "minLoanAmount": "Monto Mínimo del Préstamo",

  "orderId": "Solicitar ID",
  "orderIdError":
    "Guarde la identificación del pedido antes de desembolsar el préstamo.",

  "MULTIPLE_ACTIVE_LOANS":
    "Usted tiene múltiples préstamos activos $. Por favor pague los préstamos activos para solicitar uno nuevo.",
  "INVALID_LOAN_COMBINATION":
    "El préstamo solicitado $ no tiene una combinación válida con los préstamos activos #. \n Combinaciones válidas : ^",

  "cedulaWarning":
    "Esta no es una cédula de $ correspondiente al propietario de mini mart en préstamos anteriores (#).",
  "cedulaMODWarning": "Esta cédula $ ya existe para los colmados #.",
  "cedulaHistory": "Historia de la Cédula",
  "downloadFrontPhoto": "Descargar Foto de Frente",
  "downloadBackPhoto": "Descargar Foto Trasera",
  "oldCedulaNumber": "Antiguo Número de Cédula",
  "newCedulaNumber": "Nuevo Número de Cédula",

  //Rewards
  "RewardCatalog": "Catálogo de Recompensas",
  "AddReward": "Agregar Recompensa",
  "addRewardDetails": "Agregar Detalles de la Recompensa",
  "updateRewardDetails": "Actualizar los Detalles de la Recompensa",
  "Rewards": "Premio",
  "expiryInMonths": "Caducidad en meses",
  "expiryError": "Introduzca los meses de caducidad válidos",
  "rewardAdded": "Recompensa añadida con éxito.",
  "rewardUpdated": "Recompensa actualizada con éxito.",

  //Reward Approval
  "rewardApprovalFeatureTitle": "PANEL DE APROBACIÓN DE RECOMPENSAS",
  "rewardApprovalPageTitle": "Panel de Aprobación de Recompensas",
  "rewardApprovalDetailsTitle":
    "Detalles de Aprobación de Recompensas",
  "rewardInfo": "Información de Recompensa",
  "totalRewardPoints":
    "Los puntos de recompensa de minimart después de aprobar esta solicitud serán: ",
  "PRODUCT_NOT_FOUND": "Producto no encontrado.",
  "FILE_UPLOAD_FAIL": "No se pudo cargar el archivo.",
  "INSUFFICIENT_REWARD_POINTS":
    "Tiene puntos de recompensa insuficientes para pedir este producto.",
  "rewardHistory": "Historial de Recompensas",
  "rewardType": "Tipo de Recompensa",
  "SPENT": "Gastó",
  "EARNED": "Ganado",
  "LOAN_CLOSURE": "Cierre de Préstamo",
  "PRODUCT_PURCHASE": "Compra de Producto",
  "rewardReason": "Motivo de la recompensa",
  "insuffientRewardPoints":
    "Este minimart tiene puntos de recompensa insuficientes para su aprobación. Por favor, rechace esta solicitud.",

  "creationDate": "Fecha de Creación",

  "minimartLevelUpdateSuccess":
    "Nivel colmado actualizado con éxito.",
  "forgotPassword": "Has Olvidado tu Contraseña", //
  "MINIMART_BLOCKED": "Colmado está bloqueado.",

  "areNeighbourReferencesSatisfactory":
    "¿Son vecinos referencia satisfactoria?", //

  //T-presta Loan Commission
  "TPRESTA_LOAN": "T Presta Préstamo",
  "TPRESTA_COLLECTION": "T Presta Colección",

  "selectApprovalType": "Seleccione Tipo de Aprobación",
  "MANUAL_APPROVED": "Aprobación Manual",
  "AUTO_APPROVED": "Aprobación Automática",
  "referralReward": "RECOMPENSA POR RECOMENDACIÓN",
  "whatsAppNum": "¿Número de WhatsApp?",
  "minimartOwnerVerification":
    "Los detalles de verificación del propietario de colmado fallaron:",
  "mergeMinimart": "Fusionar Colmado",
  "DISABLED_MERGE_MINIMART_BUTTON": "Minimart no se puede fusionar porque el mensajero no está asignado",
  "mergeMinimartAlert": "Advertencia: El minmart seleccionado se eliminará del sistema después de fusionarse.",
  "merge": "Unir",
  "mergeMinimartWarning": "Colmado $ se fusionará con Colmado $$",
  "minimartMergedSuccessfully": "Colmado fusionado con éxito",
  "paymentReverse": "Reversión de Pago",
  "paymentReverseWarning":
    "¿Está seguro de que desea revertir todos los pagos de hoy?",
  "paymentReverseSuccessfully": "Pago invertido con éxito",
  "warningForInvalidDate": "Por favor seleccione fecha válida.",
  //Minimart Address
  "Sector": "Sector",
  "Street": "Calle",
  "Reference": "Referencia",

  // Add Invoice
  "AddInvoice": "Agregar Factura",
  "addInvoiceSuccess": "Factura añadida con éxito.",
  //Invoice Approval
  "invoiceApprovalPageTitle": "Aprobación de Factura",
  "invoiceApprovalFeatureTitle": "APROBACIÓN DE FACTURA",
  "invoiceDetails": "Detalles de la Factura",
  "invoiceAPPROVED": "Factura aprobada con exito.",
  "invoiceREJECT": "Factura rechazada con éxito.",

  //EMI Configuration
  "frequency": "Frecuencia",
  "WEEKLY_ONCE": "Semanal",
  "WEEKLY_TWICE": "2 veces a la semana",
  "WEEKLY_THRICE": "Interdiario",
  "WEEKLY_SIX": "Diario",
  "MONTHLY_ONCE": "Mensual",
  "MONTHLY_TWICE": "Quincenal",

  "markExceptional": "Marcar Excepcional",
  "markNormal": "Marcar Normal",
  "markExceptionalWarn":
    "¿Quieres marcar este colmado como excepcional?",
  "markNormalWarn": "¿Quieres marcar este mini mart como normal?",

  "thresholdLoanAmount": "Monto umbral del préstamo",
  "requestedFrequency": "Frecuencia Solicitada",
  "approvedFrequency": "Frecuencia Aprobada",

  //Errors
  "PAYMENT_NOT_FOUND": "No se encontró pago por un día.",
  "REVERT_PAYMENTS_NOT_POSSIBLE":
    "No se puede revertir el pago porque se tomó alguna otra acción después del pago",
  "REMITTANCE_RAISED_ERROR":
    "Recibió un error del servidor central de remesas al generar la remesa automáticamente. Inténtelo de nuevo. ",
  "MESSENGER_ALREADY_ASSIGNED": "Messenger ya está asignada hoy.",
  "NOTHING_TO_COLLECT":
    "El monto de la cobranza es cero, no se puede asignar messenger a un colmado",
  "MINIMART_SPLIT_ADD_ERR":
    "Divida la dirección del colmado en ciudad, sector, calle, número y referencia para homologar colmado.",
  "THE_LOAN_STATE_CANNOT_BE_CHANGED":
    "El estado del préstamo no se puede cambiar.",
  "INVOICE_BALANCE_ERROR":
    "El saldo de la factura debe ser inferior al importe de la factura.",
  "INVOICE_NOT_FOUND": "Factura no encontrada.",
  "ROUTE_NOT_ASSIGNED_FOR_MINIMART": "Ruta no asignada al colmado.",
  "FREQ_ERROR": "Seleccione la frecuencia.",
  "DISAPPEARED_LOAN_CANNOT_PAYOFF":
    "No podemos procesar la solicitud de liquidación de un préstamo desaparecido.",
  "PAYMENT_CANNOT_BE_REVERTED": "El pago no se puede revertir.",

  "Service unavailable": "Servicio no disponible.",
  "Step timeout": "Timeout de este paso.",
  "Document number is not defined":
    "El número de Documento no esta definido.",
  "This user has been verified more than once":
    "Este usuario ha sido verificado en mas de una ocasión.",
  "Birth Date is not defined":
    "Fecha de Nacimiento no esta definida.",
  "Full Name is not defined": "Nombre Completo no esta definido.",
  "INVALID_COLLECTION_CYCLE_CHANGE_REQUEST":
    "No se pueden cambiar los días de cobro para préstamos cerrados/inactivos", //
  "INVALID_COLLECTION_CYCLE_CHANGE":
    "Solicitud de cambio de ciclo de días de recogida no válida", //
  "PROVISION_SCHEME_ERROR":
    "El esquema de provisión ha sido actualizado con nuevas reglas. Está disponible para los préstamos desembolsados ​​después del 18 de febrero de 2022.", //

  //LEVEL Configuration
  "minCreditScore": "Puntaje de crédito mínimo", //
  "minla": "Monto mínimo", //
  "maxla": "Monto máximo", //
  "flma": "Monto máximo del primer préstamo", //
  "slma": "Monto máximo del segundo préstamo", //
  "minimumLoanAmountWarning":
    "El monto mínimo del préstamo debe ser menor que el monto máximo del préstamo.", //
  "maximumLoanAmountWarning":
    "El monto máximo del préstamo debe ser menor o igual al límite de crédito.", //
  "errorDuplicateLoanType": "Tipo de préstamo duplicado",

  //Credo
  "credoCreditScore": "Puntaje de Crédito de Credo", //
  "minCredoCreditScore": "Puntuación mínima de crédito de Credo", //

  "lowCredoCreditScoreWarning":
    "El puntaje de crédito de Credo es bajo", //

  //Credo error codes
  "CREDO_DATASET_INSIGHT_RESPONSE_PARSING_FAILED":
    "Error al analizar la respuesta de información del conjunto de datos.", //
  "CREDO_LOAN_APPLICATION_NOT_FOUND":
    "No se encontró la identificación de la solicitud de préstamo en el repositorio de Credo.", //
  "oldPasswordSameAsNew":
    "La contraseña anterior y la contraseña nueva son las mismas.", //
  "term": "Término", //
  "PleaseFillAllFields": "Por favor llena todos los espacios", //

  //Loan details screen warnings
  "EMIRoundOffWarning":
    "El capital impago puede diferir del monto del préstamo en algunos pesos debido al redondeo del EMI.", //
  "repaymentPlanNote": "Nota del plan de pago",
  "tvd": "Día de la visita del camión",
  "lateFeesWarning":
    "No se calcularán los cargos por pagos atrasados ​​para este préstamo porque se encuentra en un estado",
  "oldMinimartDetails": "Antiguo minimart detalles", //

  "stopAtOperationsAmount":
    "Detener el préstamo en Operaciones si el monto es mayor a",

  "scalaRegistrationWarning":
    "De forma predeterminada, se guardará la información del minimercado existente. Si desea cambiar algún campo en función de los nuevos datos de la solicitud, edite la información.",
  "sellerZones": "Zonas de vendedor",
  "messengerZones": "Zonas de mensajero",
  "messengerZone": "Zona de mensajero",
  "EXISTING_POSITION": "Puesto existente",
  "NEW_POSITION": "Nueva posición",
  "positionName": "Nombre de la posición",
  "BRANCH_CANT_BE_NULL": "La rama no puede ser nula",
  "ZONE_ALREADY_EXISTS": "El nombre de la zona ya existe",
  "NO_MESSENGER_FOUND_FOR_GIVEN_POSITION": "Seleccione un puesto de mensajero no vacante para que minimart no pueda quedar huérfano",
  "EXISTING_ZONE": "Zona existente",
  "NEW_ZONE": "Nueva zona",
  "selectZones": "Seleccionar zonas",
  "notWorking": "Esta característica no está funcionando actualmente.",
  "unBarMinimart": "Sin restricciones",
  "approvedMinimartSalespersonAssignment": "La solicitud de registro para el minimart está aprobada, ¿cuándo desea que se le asigne un vendedor?",
  "minPaidPercent": "Porcentaje de renovación de préstamo",
  "renewalCriteria": "Criterios de renovación",
  "CASH_TOP_UP_LOAN_IS_ALREADY_ACTIVE": "El minimart ya tiene un préstamo activo de recarga en efectivo. El préstamo solicitado no puede ser aprobado. Favor de aprobar el préstamo con conciliación.",
  "INVALID_LOAN_APPROVAL_REQUEST": "El minimart ya tiene 2 préstamos activos. El préstamo solicitado no puede ser aprobado. Favor de aprobar el préstamo con conciliación.",
  "AllMinimarts": "Todos los minimarts",
  "scala_registered": "Registrado en Scala",
  "non_scala_registered": "No registrado en Scala",
  "REPORT_FOR_DISBURSED_LOAN": "El informe solo se puede crear para el préstamo desembolsado.",
  "REPORT_NOT_AVAILABLE_FOR_OLD_LOAN": "El informe no está disponible para préstamos antiguos.",
  "isOldLoanActiveWarning" : "Nota : No se aceptará el pago de este préstamo. Por favor, reestructure este préstamo para aceptar su pago.",

  //Blacklist client
  "clientPortal": "/portalDelCliente",
  "CLIENT_PORTAL": "Portal del cliente",
  "clientId": "ID del cliente",
  "identificationNumber": "Número de identificación",
  "selectClientState": "Seleccionar estado del cliente",
  "noteType": "Tipo de nota",
  "selectIdentificationType": "Seleccionar tipo de identificación",
  "identificationType": "Tipo de identificación",
  "customerName": "Nombre del cliente",
  "clientBlacklisted": "Cliente en la lista negra",
  "CEDULA": "Cédula",
  "PASSPORT": "Pasaporte",
  "BLACKLISTED": "En la lista negra",
  "clientStatusChange": "Cambiar el estado del Cliente : ",
  "confimationWarningToBlacklist": "¿Estás seguro de que quieres incluir a este cliente en la lista negra?",
  "confimationWarningToRemoveFromBlacklist": "¿Está seguro de que desea eliminar este cliente de la lista negra?",
  "activeLoansList": "Las siguientes son las solicitudes de préstamo activas de este cliente:",
  "OWNER_OF_MINIMART_IS_BLACKLISTED": "El propietario del minimart está en la lista negra",
  "statusChangedSuccessfully": "Cambió exitosamente el estado.",
  "ALREADY_ASSIGNED_POSITION": "Puesto ya asignado",
  "editOldMinimartDetails": "Editar detalles del antiguo minimercado",
  "editCedula": "Editar cédula",
  "CLIENT_ALREADY_EXIST": "El cliente ya existe.",
  "sameCedulaError": "La identificación de la cédula ingresada es la misma que la antigua identificación de la cédula solicitada.",
  "CLIENT_NOT_FOUND": "El cliente para el que se debe actualizar la cédula no se encuentra en la base de datos.",
  "INVALID_CEDULA_UPDATE_REQUEST": "Se solicita un cambio de cédula no válido.",
  "CEDULA_DETAILS_MISMATCH": "Los detalles mencionados en la solicitud de cambio de cédula no son los registros del sistema.",

  //Supplier configuration
  "BANK_DETAIL_CONFIGURATION": "Configuración de detalles bancarios",
  "bankDetailConfiguration": "/bancoDetalleConfiguración",
  "accountTypes": "Tipos de cuenta",
  "accountType": "Tipo de Cuenta",
  "bankDetails": "Detalles del banco",
  "bankName": "Nombre del Banco",
  "bankInfo": "Información bancaria",
  "confimationWarningToDisableAccountType": "¿Quieres desactivar el tipo de cuenta?",
  "confimationWarningToEnableAccountType": "¿Quieres habilitar el tipo de cuenta?",
  "enableWarningOfAssociatedBanks": "El tipo de cuenta seleccionado se habilitará para todos los bancos asociados.",
  "disableWarningOfAssociatedBanks": "El tipo de cuenta seleccionado se desactivará para todos los bancos asociados.",
  "warningForBanklist": "Los siguientes bancos tienen sólo un tipo de cuenta asociada. Por lo tanto, estos bancos quedarán deshabilitados si este tipo de cuenta está deshabilitado:",
  "activeAccount": "La cuenta está activa.",
  "inActiveAccount": "La cuenta está inactiva.",
  "activeBank": "El banco está activo.",
  "inactiveBank": "El banco esta inactivo.",
  "minimumThreeCharacterLength": "La longitud mínima de caracteres debe ser 3.",
  "INVALID_REQUEST": "Solicitud no válida.",
  "REQUEST_ALREADY_EXISTS": "La solicitud ya existe.",
  "ACCOUNT_DETAILS_NOT_FOUND": "Cuenta no encontrada para este banco. Por favor agregue el tipo de cuenta.",
  "manageSupplier": "/gestionarProveedor",
  "addSupplier": "Agregar proveedor",
  "businessName": "Nombre del Negocio",
  "contact": "Contacto",
  "accountNumber": "Número de Cuenta",
  "SupplierCatalog": "Catálogo de proveedores",
  "updateSupplier": "Actualizar proveedor",
  "MANAGE_SUPPLIER": "GESTIONAR PROVEEDOR",
  "supplierInfo": "Información del Proveedor",
  "sdt": "Tipo de desembolso seleccionado",
  "stat": "Tipo de cuenta seleccionada",
  "Browse": "Navegar",
  "transferReceipt": "Recibo de transferencia",
  "FILE_UPLOAD_NOT_ALLOWED": "No se permite la carga de archivos.",
  "DELIVERY": "Entrega",
  "TRANSFER": "Transferir",
  "PERSONAL_ACCOUNT": "Cuenta personal",
  "SUPPLIER_ACCOUNT": "Proveedor cuenta",
  "TRANSFER_RECEIPT_NOT_FOUND": "Cargue el recibo de transferencia para desembolsar el préstamo.",
  "supplierInactiveWarning": "El proveedor seleccionado para este préstamo está inactivo.",
  "bankInactiveWarning": "Los datos bancarios seleccionados están inactivos en el sistema.",
  "rnc": "RNC",
  "supplierAddedSuccessfully": "Proveedor agregado exitosamente",
  "supplierUpdatedSuccessfully": "Proveedor actualizado exitosamente",
  "bankNames": "Nombres de bancos",
  "addAccountType": "Agregar Tipo de Cuenta",
  "addBank": "Agregar Banco",
  "editAccountType": "Editar Tipo de Cuenta",
  "selectBankStatus": "Seleccione Estado del Banco",
  "selectSupplierStatus": "Seleccione el Estado del Proveedor",
  "SUPPLIER_ALREADY_EXIST": "Ya existen proveedores con el mismo RNC.",
  "RNC_NOT_FOUND": "RNC no encontrado",
  "RNC_SERVICE_NOT_ACCESSIBLE": "No se puede acceder al servicio RNC",
  "businessNameNotValid": "El nombre de la empresa no es válido",
  "contactNotValid": "Contacto no válido",
  "phoneNumberNotValid": "Número de teléfono no válido",
  "bankNameNotValid": "El nombre del banco no es válido",
  "accountTypeNotValid": "Tipo de cuenta no válido",
  "accountNumberNotValid": "El número de cuenta debe tener entre 6 y 30 dígitos",
  "statusNotValid": "Estado no válido",
  "ACCOUNT_NUMBER_PATTERN_MISMATCH": "El número de cuenta no es numérico.",
  "RNCVerification": "Verificación del RNC",
  "RNC_Unverified_Warning": "El RNC de este proveedor no está verificado. Al guardar la información se intentará la reverificación.",
  "Verified": "Verificado",
  "UnVerified": "Sinverificar",
  "phoneNumber": "Teléfono",
  "loanFrom": "Préstamo creado a partir de",

  //GeoLocation
  "GeoLocation": "Ubicación Geográfica",
  "latitude": "Latitud",
  "longitude": "Longitud",
  "latitudeValidRange": "El rango válido de latitud es de -90 a 90 grados.",
  "longitudeValidRange": "El rango válido de longitud es de -180 a 180 grados.",

  //Deactivation
  "userAvailability": "Disponibilidad del Usuario",
  "USER_CANT_DEACTIVATE_ITSELF": "No puedes desactivarte a ti mismo. Por favor, solicite la autorización del usuario.",
  "USER_HAVE_SUBORDINATES": "Este usuario tiene subordinados. Por favor, distribúyalos para desactivar al usuario.",
  "USER_HAVE_ONGOING_LOANS": "Este usuario tiene un trabajo asignado para hoy. Puede desactivarlo después de que se complete el trabajo.",
  "NO_ADJACENT_USER_FOUND": "No se puede desactivar al usuario ya que no se ha encontrado ningún usuario adyacente en el sistema",
  "USER_NOT_FOUND_TO_HANDLE_WORKLOAD": "No hay ningún usuario disponible para manejar la carga de trabajo del usuario original.",
  "WorkloadDistributionModal": "Distribución de la Carga de Trabajo",
  "canDeactivateModerator": "Este usuario no tiene subordinados. Puede desactivar el usuario.",
  "canDeactivateUser": "Este usuario no tiene trabajo asignado para hoy. Puede desactivar el usuario.",
  "deactivated": "Este usuario está desactivado",
  "Deactivated": "Desactivado",
  "userDeactivatedSuccessfully": "Usuario desactivado con éxito",

  //Unavailability
  "USER_CANT_UNAVAILABLE_ITSELF": "No puede marcarse como no disponible. Por favor, solicite la autorización del usuario.",
  "AVAILABLE_USER_HAVE_SUBORDINATES": "Este usuario tiene subordinados. Por favor, distribúyalos para marcar al usuario como no disponible.",
  "AVAILABLE_USER_HAVE_ONGOING_LOANS": "Este usuario tiene un trabajo asignado para hoy. Puede marcarlo como no disponible después de que se complete el trabajo.",
  "NO_ADJACENT_AVAILABLE_USER_FOUND": "No se puede marcar al usuario como no disponible ya que no hay ningún usuario adyacente en el sistema.",
  "ConfirmAvailableUser": "¿Quieres marcar este usuario como disponible?",
  "ConfirmUnavailableUser": "¿Quieres marcar a esta usuaria como no disponible?",
  "Availability": "Disponibilidad",
  "canUnavailableModerator": "Este usuario no tiene subordinados. Puede marcar el usuario como no disponible.",
  "canUnavailableUser": "Este usuario no tiene trabajo asignado para hoy. Puede marcar el usuario como no disponible.",
  "canAvailableUser": "¿Realmente quieres marcar al usuario como disponible?",
  "available": "Este usuario está disponible",
  "unavailable": "Este usuario no está disponible",
  "unavailableReason": "No puede hacer que este usuario no esté disponible porque",
  "workloadCountDisplay": "Número de préstamos a distribuir :",
  "sameInput": "No hay ningún cambio nuevo que guardar",
  "Available": "Disponible",
  "Unavailable": "No disponible",
  "userIsUnavailableNow": "El usuario no está disponible ahora.",
  "userIsAvailableNow": "El usuario ya está disponible.",

  //Update loan
  "LOAN_UPDATE_NOT_ALLOWED": "No se permite la actualización del préstamo.",
  "ageMustBeInBetweenTwentyOneToSixtyFive": "La edad debe estar entre 21 y 65 años.",
  "BASIC_INFO_CANNOT_BE_NULL": "La información básica del préstamo no puede ser nula",
  "INVALID_BASIC_INFO_DATA": "Algunos campos de la información básica contienen datos que no están presentes en la configuración.",
  "INVALID_INCOME_DATA": "Algunos campos de los ingresos contienen datos que no están presentes en la configuración.",
  "INVALID_SITE_VISIT_DATA": "Algunos campos de la visita al sitio contienen datos que no están presentes en la configuración.",
  "INVALID_EXPENSES_DATA": "Algunos campos de los gastos contienen datos que no están presentes en la configuración.",
  "formValuesNotPresentInConf": "Algunos campos de la solicitud de préstamo no están presentes en la configuración, por favor compruébelo.",

  //Conciliation
  "EMI_CALCULATION_NOT_FOUND": "No se han encontrado detalles de EMI para cualquiera de los préstamos activos",
  "NEW_LOAN_AMOUNT_INSUFFICIENT_FOR_CONCILIATION": "El monto solicitado para el nuevo préstamo es menor que el monto de reembolso del préstamo activo.",
  "CASH_TOPUP_LOAN_IS_ACTIVE": "Un préstamo de recarga de efectivo ya está activo, por lo que cualquier otro préstamo no se puede conciliar.",
  "RAISE_REMITTANCE_BEFORE_DISBURSEMENT": "Por favor, genere la remesa antes de desembolsar el préstamo.",

  //Administrative expense configuration
  "ConfigurationManagement_ADMINISTRATIVE_EXPENSE_DATA": "GASTOS ADMINISTRATIVOS",
  "ADMINISTRATIVE_EXPENSE_DATA": "GASTOS ADMINISTRATIVOS",
  "administrativeExpense": "Gastos Administrativos ($)",
  "amountCharged": "Monto Cobrado ($)",
  "receiptIds": "ID de Recibo",
  //Payment Frequencies Configuration
  "exceptional": "Excepcional",
  "nonExceptional": "No Excepcional",
  "restructure": "Reestructurar ($) : ",
  // Loan Status and Grade Configuration
  "LOAN_STATUS_AND_GRADE": "Estado de Préstamo y Calificación",
  "Other": "Otro",

  //Change role
  "changeRole": "Cambiar Rol",
  "changeRoleWarning": "Estás en el proceso de cambio de rol.",
  "workloadWarning": "Este usuario tiene carga de trabajo, tendrá que distribuir la carga de trabajo antes de cambiar el rol.",
  "notAllowedToChangeToThisRole": "No se permite cambiar a este rol.",
  "USER_CANT_CHANGE_ROLE_ITSELF": "No puedes cambiar de rol tú mismo. Por favor, solicita al usuario autorizado.",
  "USER_HAVE_SUBORDINATES_TO_CHANGE_ROLE": "Este usuario tiene subordinados. Por favor, distribúyalos para cambiar el rol del usuario.",
  "USER_HAVE_ONGOING_LOANS_TO_CHANGE_ROLE": "Este usuario tiene un trabajo asignado para hoy. Puede cambiar el rol después de que se complete el trabajo.",
  "NO_ADJACENT_USER_FOUND_TO_CHANGE_ROLE": "No se puede cambiar el rol del usuario ya que no se ha encontrado ningún usuario adyacente en el sistema.",
  "confirmChangeRole": "¿Quieres cambiar el rol de este usuario?",
  "sameRoleSelected": "El rol seleccionado es el mismo que el rol actual.",
  "userRoleChangedSuccessfully": "El usuario no está disponible ahora.",

  //User roles
  "resolveComplaintNotAllowed": "No se permite resolver la queja.",
  "resolveLoanRequestNotAllowed": "No se permite resolver la solicitud de préstamo.",

  //Reports admin expense
  "paidAdminExpense": "Gastos Administrativos Pagados",
  "amountCollectedInCash": "Monto Cobrado en Efectivo",
  "amountCollectedInDeposit": "Monto Cobrado en Depósito",
  "amountCollectedInTransfer": "Monto Cobrado en Transferencia",
  "collectedAdminExpenseInCash": "Gastos administrativos cobrados en efectivo",

  //select collections days
  "requestedCollectionDays": "Días de Recogida Solicitados",
  "approvedCollectionDays": "Días de Recogida Aprobados",
  "st": "ro",
  "nd": "do",
  "rd": "ro",
  "th": "to",
  "mergeMinimartCollectionDaysWarning": "Información: Estos 2 minimarts tienen diferentes ciclos de recogida, tendrá que cambiar el ciclo de recogida de los préstamos activos # del minimart $.",
  "currentCollectionCycle": "Ciclo de Cobro Actual",
  "notAllowedForThisLoanType": "No se permite para este tipo de préstamo.",
  "notAllowedForSelectedFrequency": "No se permite para la frecuencia seleccionada.",
  "termsUnavailable": "Términos no disponibles",

  //Error codes
  "AMOUNT_APPROVED_CHANGE_NOT_ALLOWED": "No se permite el cambio de importe.",
  "NO_DUE_INSTALLMENT_FOUND_FOR_CHANGIING_COLLECTION_CYCLE": "No se encontró ninguna cuota pendiente para cambiar el ciclo de recogida.",
  "NEGOTIATION_UPDATE_NOT_ALLOWED": "La negociación no se puede actualizar.",
  "NEGOTIATION_UPDATE_BY_ANALYST": "Solo el analista puede actualizar la negociación.",
  "NEGOTIATION_UPDATE_BY_OPERATION_USER": "Solo el usuario de la operación puede actualizar la negociación.",
  "INVALID_FREQUENCY": "Frecuencia no válida.",
  "INVALID_COLLECTION_DAYS": "Días de recogida no válidos.",
  "FREQUENCY_SHOULD_NOT_BY_EMPTY": "La frecuencia no debe estar vacía.",
  "MINIMART_CANNOT_BE_MARKED_NORMAL": "Minimart no puede ser marcado como normal ya que tiene préstamos en curso.",
  "INVALID_LOAN_AMOUNT_REQUEST": "Se solicita un monto de préstamo no válido.",
  "INVALID_INSTALLMENT_DATE_FOR_CHANGE_COLLECTION_CYCLE": "Fecha de pago no válida para cambiar el ciclo de cobro.",
  "LOAN_APPLICATION_FORM_NOT_FILLED": "La solicitud no se puede procesar porque no se ha completado el formulario del préstamo desembolsado para el ID de préstamo $.",
  "INVALID_PROVISION_TYPE": "Tipo de provisión no válido.",
  "TERMS_ARE_REPEATED": "Los términos se repiten.",
  "DAYS_PASTDUE_ASCENDING_VERIFICATION_FAILED:": "La verificación de días vencidos ascendentes falló.",
  "OBSOLETE_PROVISION_TERM_CONFIGURATION": "El término configuración en provisión es obsoleta.",
  "OBSOLETE_CONFIGURATION":"La configuración es obsoleta y ya no se utiliza.",
  "INVALID_DISCOUNT_PERCENTAGE": "Porcentaje de descuento no válido.",
  "LOAN_INTEREST_NOT_PRESENT": "El interés del préstamo no está presente.",
  "SOME_ACTION_IS_PERFORMED_AFTER_DISCOUNT": "Se realizó alguna acción después del descuento.",
  "DISCOUNT_NOT_APPLIED": "El descuento no se aplicó.",
  "LOAN_ALREADY_CLOSED": "El préstamo ya está cerrado.",
  "PAYMENT_CANNOT_BE_DONE_FOR_V1_LOANS":"No se puede realizar el pago de préstamos V1.",
  "DEPOSIT_RECEIPT_NOT_FOUND": "Recibo de depósito no encontrado.",

  //Payment collection
  "payForMinimart": "Pagar por minimart",
  "payForLoan": "Pagar por préstamo",
  "downpaymentCollection": "Colección de pago inicial",
  "invoiceNumber": "Número de Factura",
  "todaysAmount": "Monto de Hoy",
  "pastDue": "Pasar Debido",
  "clientStatus": "Estado del Cliente",
  "collectPayment": "Recoger Pago",
  "payOff": "Pagar",
  "paymentMode": "Modo de Pago",
  "doYouWantNewLoan": "¿Quieres un nuevo préstamo?",
  "DEPOSIT": "Depósito",
  "CASH": "Efectivo",
  "MOBILE": "Móvil",
  "WEB": "Web",
  "transferReceipts": "Recibos de transferencia",
  "paymentModes": "Modos de pago",
  "paymentSources": "Fuentes de pago",
  "charactersAllowed": "Solo se permiten",
  "changeReceipt": "Cambiar Recibo",
  "selectReceipt": "Seleccionar Recibo",
  "viewReceipt": "Ver Recibo",
  "amountToPayOff": "Monto a Pagar",
  "amountToPayShouldNotBeGreaterThanPayOff": "El monto a pagar no debe ser mayor que el monto a pagar.",
  "downpaymentTransferReceipt": "Recibo de transferencia de pago inicial",
  "paymentSource": "Fuente de Pago",
  "paymentCollectedSuccessfully": "Pago recogido con éxito.",
  "downPaymentCollectedSuccessfully": "Pago inicial recogido con éxito.",
  "INVALID_DOWNPAYMENT": "El monto del pago inicial es inferior al 10 % del costo de la enseres y Equipos.",
  "MOTORCYCLE_NOT_FOUND": "Enseres y Equipos no encontrada.",
  "LOAN_NOT_FOUND_FOR_COLLECTION": "Este minimercado no cuenta con préstamo para cobro.",
  "COLLECTION_FAILED": "Error en la recolección, inténtelo de nuevo con una cantidad válida.",
  "DUE_LOAN_NOT_FOUND": "No se encontró el préstamo pendiente.",
  "INSUFFICIENT_ADMINISTRATIVE_EXPENSE_AMOUNT": "Por favor realizar el pago por un monto de $ para los gastos administrativos.",
  "FILE_NOT_FOUND": "Archivo no encontrado.",
  "transferReceiptNotUploaded": "Recibo de transferencia no cargado",
  "pleaseSelectLoan": "Por favor, seleccione el préstamo",
  "collectedBy": "Recogido por",

  //Grade upgrade
  "UPGRADE": "Actualizar",
  "DOWNGRADE": "Degradar",
  "changeGrade": "Cambiar Grado",
  "MINIMART_WITH_GRADE_A_CANNOT_BE_UPGRADED": "El minimart con grado 'A' no puede ser actualizado.",
  "MINIMART_WITH_GRADE_D_CANNOT_BE_DOWNGRADED": "El minimart con grado 'D' no puede ser degradado.",
  "gradeUpdateConfirmation": "¿Quieres cambiar la grado del minimart?",
  "gradeResetConfirmation": "¿Quieres restablecer la grado esta noche?",
  "systemWillUpdateNote": "Nota : El sistema actualizará el grado de minimart todas las noches.",
  "systemWillNotUpdateNote": "Nota : El sistema no actualizará el grado del minimart todas las noches.",
  "gradeResetActive": "Reinicio de calificaciones activo",
  "gradeResetInactive": "Reinicio de calificaciones inactivo",

  // deactivate account
  "OWNER_OF_MINIMART_IS_DEACTIVATED": "Este minimercado desactivó su cuenta y se registró nuevamente.",
  "DEACTIVATED": "Desactivado",
  "BL_DEACT": "Lista negra y deshabilitada",

  //Loan already closed
  "isConciliatedLoanClosed": "El préstamo $ conciliado con este préstamo se cierra.",

  // Merged Minimarts
  "mergedMinimarts": "Minimercados fusionados",
  "mergedHistory": "Historia fusionada",
  "disbursedLoans": "Préstamos desembolsados",
  "rejectedLoans": "Préstamos rechazados",
  "inprocessLoans": "Préstamos en proceso",
  "mergingApprovedOn": "Fusión aprobada el",
  "mergingApprovedBy": "Fusión aprobada por",
  "mergedMinimartHistory": "Historia del minimercado fusionado",

  // Conciliation
  "NEW_LOAN_AMOUNT_NOT_SUFFICIENT": "El monto del nuevo préstamo no es suficiente para conciliar el préstamo seleccionado.",
  "ACTIVE_CASHTOPUP_LOAN_FOUND": "El préstamo de recarga de efectivo ya está activo, el cual deberá conciliarse.",
  "MULTIPLE_LOANS_CANT_BE_CONCILIATED": "Sólo se pueden Conciliar 2 Préstamos.",
  "AMOUNT_IS_NOT_VALID": "El monto ingresado no es válido.",
  "INVALID_DAYS": "Días de llamada de credo no válidos",
  "createdByConciliation": "Creado por Conciliación",

  //PaymentFrequecies Configuration
  "PAYMENT_FREQUENCY": "Frecuencias de Pago",
  "discountInterestAmount": "Monto de interés de descuento",

  //CREDO
  "credoCallDays": "Días de Credo Convocatoria (días)",

  //Ci loan request report
  "REQUEST_CREATED": "Solicitud Creada",
  "PROCESSED_WITH_FAILURE": "Procesado con falla",
  "minimartCiCode": "Código Ci Minimart",
  "CIInvoiceAmount": "Monto",
  "CIInvoiceBalanceAmount": "Balance",
  "CIInvoiceDate": "Fecha de Creación",
  "CIInvoiceExpiryDate": "Expiración",
  "CIInvoiceCreatedDate": "Fecha solicitada",
  "invalidData": "Por favor introduce un dato válido",
  "CI_LOAN_REQUEST_REPORT": "Informe_de_Solicitud_de_Préstamo_Ci",
  "LOAN_FORM_NOT_FILLED": "Como minimart id * ha desembolsado préstamo cuyos datos aún no están completados,El préstamo con numero de solicitud #, no se puede aprobar con conciliación.",

  "Restructured": "Involucrado en la Reestructuración",
  "NonRestructured": "No reestructurado",
  "CreatedAndClosedRestructuredLoan": "Creado y cerrado por reestructuración",
  "NoSellerAssignedWarning": "El vendedor no está asignado a minimart con código CI *.",
  "noteAddedSuccessfully": "Nota agregada exitosamente.",
  "invalidNote": "Sólo se permiten letras, números, espacios y caracteres especiales.",

  //Apply discount screen
  "ApplyDiscount": "Aplicar Descuento",
  "calculate":"Calcular",
  "interestBeforeDiscount": "Interés antes del descuento",
  "interestAfterDiscount": "Interés después del descuento",
  "errorDiscountNotCalculated":"por favor calcule el descuento primero",
  "emiAfterDiscount": "EMI después del descuento",
  "emiBeforeDiscount": "EMI antes del descuento",
  "manualDiscountHistory": "Historial de Descuentos Manuales",
  "emiInterestAfterDiscount": "Interés EMI después del descuento ",
  "emiInterestBeforeDiscount": "Interés EMI antes del descuento ",
  "balanceToDateAfterDiscount": "Saldo a la fecha después del descuento ",
  "balanceToDateBeforeDiscount": "Saldo a la fecha antes del descuento ",
  "ipe": "Interés por EMI ($)",
  "revertDiscount": "Revertir Descuento",
  "discountRevertInfo": " El descuento no se puede revertir si se realiza algina de las siguientes acciones:",
  "eliminateArrears": "Eliminar mora a Comercio",
  "makePayment": "Realizar algún tipo de cobro al comercio",
  "interest": "Interés",
  "ti": "Interés Total ($)",
  "ne": "EMI ($)",
  "Revert": "Revertir",
  "DiscountDetails": "Detalles del Descuento",
  "revertDiscountConfirmation": "¿Estás seguro de que quieres revertir el descuento?",
  "applyDiscountConfirmation": "¿Estás seguro de que quieres aplicar el descuento?",
  "loanInfoForDiscount": "Al hacer clic en el botón calcular solo estamos calculando el descuento, para aplicar el descuento haga clic en el botón aplicar descuento.",
  "MANUAL_DISCOUNT_APPLIED": "Descuento manual aplicado",
  "MANUAL_DISCOUNT_REVERTED": "Descuento manual revertido",
  "ApplyManualDiscount": "Aplicar Descuento Manual",
  "noteForAmortizations": "Notas para el Índice de Amortización : ",
  "notesForClient": "Notas para el cliente : ",
  "addNewNote": "Agregar Nueva Nota",
  "existingNotes":"Notas Existentes",
  "po_act": "Fecha / Tipo",
  "pb_rl": "Autor / Rol",
  "discountRevertedSuccessfully": "Descuento Revertido con Exito",
  "discountAppliedSuccessfully": "Descuento Aplicado con Exito",
  "manualDiscount": "Descuento Manual",
  "afterCalculatingDiscount": "Después de calcular el descuento del $, los nuevos valores son",
  "PleaseTryWithOtherDiscount": "Por favor, inténtelo con otro descuento",
  "viewHistory": "Ver Historial",
  "currentlyAppliedDiscountInfo": "Información sobre descuentos aplicados actualmente",
  "afterRevertingDiscountInfo": "Después de revertir el descuento, se aplicará este interés anterior y el emi anterior",
  "discountEmiRounding": "El interés calculado después del descuento variará debido al redondeo.",
  "di": "Interés a aplicar des",

  "paymentCannotBeReverted": "Los cobros realizados en el día de hoy no se pueden revertir después de aplicar o revertir el descuento realizado.",
  
  // Special Prospect 
  "createdBySpecialProspect": "Creado por Prospecto Especial",
  "specialProspectMinimartNote": "Nota: Este es un minimercado especial para prospectos.",
  "subType": "Subtipo de minimercado",
  "specialProspect": "Prospecto Especial",
  "matricesMinimart": "Minimercado Matrices",

  "complaintReport": "Informe de queja",
  "OTHER_OPERATION_ALREADY_INPROGRESS": "Ya hay otra operación del minimercado en marcha. Por favor, inténtelo de nuevo después de 10 minutos."

};

export default es;